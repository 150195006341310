/*
Author: Codingest
Template Name: Infinite

------------------------------------------------
Table of Content
------------------------------------------------

1. General layout CSS styles
2. Header
3. Slider
4. Post
5. Sidebar
6. Post Detail
7. Pages
8. PaginationG1863
9. Footer
10. Ad Spaces

*/

:root {
  --inf-main-color: #b78400;
}

/* ============ 1. General layout CSS styles ============ */
body {
  color: #333;
  font-family: var(--inf-font-primary);
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  overflow-x: hidden !important;
}

a {
  -moz-transition: 0.2s;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  color: #333;
  outline: none !important;
}

button {
  -moz-transition: 0.2s;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  outline: none !important;
}

a:hover {
  text-decoration: none;
}

a:focus,
a:active {
  outline: none !important;
  text-decoration: none;
}

section {
  position: relative;
}

.margin-bottom30 {
  margin-bottom: 30px;
}

img {
  text-indent: -9999px;
  color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.margin-bottom15 {
  margin-bottom: 15px;
}

.btn-sm {
  padding: 8px 20px;
  font-size: 13px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-md {
  padding: 8px 18px;
  border-radius: 3px;
  line-height: 1.5;
}

.btn-lg {
  padding: 8px 24px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-custom {
  color: #fff !important;
  font-weight: 400;
  outline: 0 !important;
  border: 0 !important;
}

.btn-custom:hover {
  opacity: 0.8;
}

.btn-block {
  padding: 14px 24px;
  border-radius: 3px;
  color: #fff !important;
}

.form-input {
  border: 1px solid #dfe0e6;
  outline: none !important;
  color: #494949;
  height: 44px;
  font-size: 14px;
  line-height: 18px;
  padding: 10px 20px;
  box-shadow: none;
  border-radius: 3px;
}

.form-textarea {
  resize: vertical;
  min-height: 100px;
  border: 1px solid #dfe0e6;
  color: #494949;
  padding: 10px 15px;
  border-radius: 3px;
  box-shadow: none !important;
}

.form-group label {
  font-weight: 600;
}

.front-message {
  font-size: 13px;
}

.has-error {
  border-color: #a94442 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.modal-center {
  text-align: center;
  padding: 0 !important;
}

.modal-center:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.modal-center .modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.margin-top-30 {
  margin-top: 30px;
}
.margin-top-50 {
  margin-top: 50px;
}

.margin-top-15 {
  margin-top: 15px;
}

.p0 {
  padding: 0 !important;
}

.m0 {
  margin: 0 !important;
}

.m-t-45 {
  margin-top: 45px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-b-15 {
  margin-bottom: 15px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.m-r-0 {
  margin-right: 0 !important;
}

.center-box {
  float: none !important;
  margin: 0 auto !important;
}

.form-control {
  box-shadow: none !important;
}

.position-relative {
  position: relative;
}

.mobile-li {
  display: none !important;
}

.error-message {
  margin-top: 15px;
  padding-left: 15px;
}

.error-message p,
.error-message ul li {
  position: relative;
  color: #d43f3a;
  display: list-item;
  margin-bottom: 5px;
}

.error-message ul {
  padding-left: 0;
}

.success-message {
  margin-top: 15px;
}

.success-message p {
  position: relative;
  color: #00a65a;
  margin-bottom: 5px;
}

.img-popular-mobile {
  display: none;
}

.center-image {
  margin: 0 auto !important;
  float: none !important;
}

.row-custom {
  display: block;
  width: 100%;
  max-width: 100%;
  float: left;
  position: relative;
}

.col-option label {
  cursor: pointer !important;
}

.not-visible {
  visibility: hidden !important;
}

.inf-svg-icon {
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin-right: 5px;
  top: -0.085rem !important;
}

.inf-svg-icon-lg {
  width: 1.2em;
  height: 1.2em;
}

/* ============ 2. Header ============ */
#header {
  border-top: 1px solid #e4e4e4;
  box-shadow: 0 1px 4px #e4e4e4;
}

.nav-container {
  position: relative;
}

.nav-top-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.nav-top {
  width: 100%;
  display: block;
  float: left;
  padding: 5px 0;
  padding-top: 4px;
}

.nav-top ul {
  padding: 0;
  margin: 0;
}

.nav-top > ul > li {
  list-style: none;
  float: left;
  height: 24px;
}

.nav-top .li-dark-mode-sw button {
  width: auto !important;
  padding: 0 10px !important;
  position: relative;
  top: 1px;
}

.nav-top ul li a {
  padding: 4px 10px !important;
  line-height: 24px;
  color: #666;
}

.nav-top ul li .span-sep {
  padding: 6px 0;
  line-height: 24px;
}

.nav-top ul li .image-profile-drop {
  padding: 0 10px !important;
}

.nav-top .left {
  float: left;
  margin-left: -10px;
  margin-right: -10px;
}

.nav-top .right {
  float: right;
}

.profile-dropdown {
  display: inline-block;
  position: relative;
}

.profile-dropdown a {
  border-radius: 0;
  display: inline-block;
  font-weight: 400;
  line-height: 60px;
  padding: 0 10px;
  position: relative;
  text-transform: none !important;
}

.profile-drop {
  display: none !important;
}

.profile-dropdown img {
  border-radius: 50%;
  display: inline-block;
  height: 24px;
  width: 24px;
  margin-right: 5px;
  position: relative;
  top: -1px;
}

.profile-dropdown .dropdown-menu-cnt {
  height: 16px;
}

.profile-dropdown .dropdown-menu {
  opacity: 1;
  width: auto !important;
  padding: 5px 0 !important;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.profile-dropdown .dropdown-menu > li {
  padding-bottom: 0;
  display: block;
}

.profile-dropdown .dropdown-menu > li > a {
  border-radius: 0;
  display: block;
  font-weight: 400;
  padding: 4px 15px !important;
  line-height: 24px !important;
  position: relative;
  text-transform: none !important;
}

.profile-dropdown .dropdown-menu > li > a > i {
  width: 15px;
}

.navbar-brand {
  padding: 0 !important;
  margin: 0 !important;
}

.navbar-brand img {
  max-width: 180px;
  max-height: 50px;
  display: block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.navbar {
  border-radius: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.navbar-nav > li {
  margin-right: 6px;
}

.li-logo {
  position: relative;
  margin-top: 0 !important;
  top: -12.5px;
}

.navbar-inverse .navbar-nav > li > a {
  padding: 22px 10px;
  margin: 0;
  border-radius: 3px;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 34px;
  background-color: transparent !important;
  font-size: 14px;
  position: relative;
  color: #444;
}

.navbar-inverse {
  border: none;
  background-color: #fff;
}

.navbar-inverse .navbar-nav > li > .navbar-brand {
  padding-left: 0;
}

.li-navbar-brand {
  flex-shrink: 0 !important;
}

.li-search {
  margin-left: auto !important;
  margin-right: 0 !important;
}

.li-search i {
  position: relative;
  top: -1px;
}

.navbar-brand img {
  display: block;
  width: 100%;
  max-width: 180px;
  max-height: 50px;
}

.navbar > .container .navbar-brand,
.navbar > .container-fluid .navbar-brand {
  margin-left: 0 !important;
}

.logo-mobile {
  display: none;
}

.dropdown-menu-cnt {
  padding-top: 10px;
  height: 1px;
  width: 100%;
  position: absolute;
  z-index: 1;
}

.nav-desktop {
  display: block;
  width: 100% !important;
  float: left;
}

.navbar-header {
  padding: 14px 0;
}

.nav-desktop li a {
  white-space: nowrap;
}

.navbar-inverse .navbar-nav .dropdown-menu {
  border: 1px solid rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  border: 0;
  margin-top: 0;
  border-top: 0;
  border-radius: 0 0 3px 3px !important;
  left: 0;
  opacity: 1;
  background: #fff;
  width: auto !important;
  border-top: 0 !important;
}

.navbar-inverse .navbar-nav .dropdown-menu:before {
  position: absolute;
  top: 0;
}

.navbar-inverse .navbar-nav .dropdown-menu > li:last-child > a {
  border-radius: 0 0 3px 3px;
}

.navbar-inverse .navbar-nav .dropdown-menu > li.divider {
  background-color: transparent;
}

.navbar-inverse .dropdown {
  left: auto;
  right: auto;
}

.navbar-inverse .dropdown .dropdown-menu > li {
  padding-bottom: 0;
  width: 100%;
  display: block;
}

.navbar-inverse .dropdown .dropdown-menu > li > a {
  width: 100%;
  border-radius: 0;
  padding: 6px 15px;
  position: relative;
  font-size: 14px;
  border-radius: 0 !important;
  color: #5e5e5e;
  display: block;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
}

.caret {
  position: relative;
  top: -1px;
}

.navbar-inverse .dropdown .dropdown-menu > li > a > i {
  width: 15px;
  margin-right: 5px;
}

.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
  background-color: #f3f3f3;
  color: #262626;
  text-decoration: none;
}

.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
  border-top: 0;
  padding-bottom: 0;
}

.navbar-inverse .navbar-toggle {
  border-radius: 2px;
}

.nav-desktop .li-navbar-brand {
  margin-right: 15px;
}

.nav-desktop .navbar-left {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.navbar-left .navbar-nav {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
}

.navbar-left span {
  font-weight: bold;
  font-size: 13px;
}

.dropdown-more .more-sign {
  font-size: 22px;
  height: 26px;
  display: block;
}

.li-sub-dropdown {
  position: relative;
}

.dropdown-menu-sub {
  display: none;
}

.sub-dropdown {
  left: 100% !important;
  top: 0;
}

.dropdown:hover .top-dropdown {
  display: block;
}

.li-sub-dropdown:hover .sub-dropdown {
  display: block !important;
}

.top-lang-dropdown {
  min-width: 160px !important;
  padding: 5px 0 !important;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.top-lang-dropdown li {
  padding: 0 5px !important;
}

.btn-switch-mode {
  width: 22px;
  text-align: center;
  border: 0 !important;
  margin: 0 !important;
  box-shadow: none !important;
  background-color: transparent !important;
  color: #666 !important;
}

.btn-switch-mode svg {
  width: 14px;
  height: 14px;
  position: relative;
  margin-right: 0 !important;
}

.dark-mode-icon {
  width: 12px !important;
  height: 12px !important;
}

.btn-switch-mode .bi-sun-fill {
  color: #fff !important;
}

/*mobile menu*/
.overlay-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  display: none;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 1001;
  animation-name: fadeIn;
}

.mobile-nav-container {
  display: block;
  width: 100%;
  position: relative;
}

.nav-mobile-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;
  display: none;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.5) 0 5px 7px -6px;
}

.nav-mobile-logo {
  width: 100%;
  display: block;
  position: relative;
  text-align: center;
}

.nav-mobile-logo a {
  display: inline-block;
}

.nav-mobile-logo a img {
  max-width: 180px;
  max-height: 50px;
}

.mobile-header-container {
  display: table;
  width: 100%;
  position: relative;
}

.mobile-header-container .mobile-menu-button {
  display: table-cell;
  vertical-align: middle;
  width: 60px;
}

.mobile-header-container .mobile-menu-button a {
  position: relative;
  display: inline-block;
  font-size: 24px;
  line-height: 24px;
  padding: 12px;
  color: #fff !important;
}

.mobile-header-container .mobile-logo {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.mobile-header-container .mobile-logo a {
  display: inline-block;
}

.mobile-header-container .mobile-logo img {
  display: block;
  width: 100%;
  max-width: 158px;
  max-height: 50px;
}

.mobile-header-container .mobile-button-buttons {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 60px;
}

.mobile-header-container .mobile-button-buttons a {
  position: relative;
  display: block;
  font-size: 22px;
  line-height: 24px;
  padding: 12px;
  color: #fff !important;
}

.mobile-header-container .mobile-button-buttons .search-icon {
  margin: 0 !important;
}

.nav-mobile {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  padding-top: 60px;
  text-align: center;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-transition: all 250ms ease;
  -moz-transition: all 250ms ease;
  -ms-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
  background-color: #fff;
}

.nav-mobile .nav-mobile-inner {
  display: block;
  position: relative;
  width: 100%;
  padding: 20px;
  padding-top: 15px;
  padding-bottom: 30px;
}

.nav-mobile .btn-close-mobile-nav {
  position: absolute;
  top: 2px;
  right: 2px;
  font-size: 18px;
  padding: 15px;
  color: #555;
}

.nav-mobile .navbar-nav {
  float: none !important;
  padding: 0;
  margin: 0;
  display: block;
  position: relative;
  width: 100%;
}

.nav-mobile .navbar-nav .nav-item {
  display: block;
  position: relative;
  width: 240px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  text-align: left;
  word-break: break-word;
  border-bottom: 1px solid #ebebeb;
}

.nav-mobile .navbar-nav .nav-item:last-child {
  border: 0 !important;
}

.nav-mobile .navbar-nav .item-profile .nav-link {
  padding-bottom: 0;
}

.nav-mobile .navbar-nav .item-profile .nav-link img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 100%;
}

.nav-mobile .navbar-nav .item-profile .dropdown-menu li {
  margin-left: 25px;
}

.nav-mobile .navbar-nav .item-sell-button a {
  white-space: nowrap;
  padding: 0.48rem 1.5rem;
}

.nav-mobile .navbar-nav .nav-item .nav-link {
  font-size: 15px;
  line-height: 22px !important;
  padding: 10px 0 !important;
  text-transform: none !important;
  font-weight: normal;
  display: block;
  width: 100%;
  color: #333;
}

.nav-mobile .navbar-nav .nav-item .nav-link i {
  font-size: 14px;
  line-height: 22px !important;
  color: #555;
}

.nav-mobile .navbar-nav .open .dropdown-menu > li {
  display: block;
  width: 240px !important;
  position: relative;
  border: 0 !important;
}

.nav-mobile .navbar-nav .open .dropdown-menu > li > a {
  color: #333;
  line-height: 22px !important;
  font-size: 15px !important;
  padding: 8px 15px !important;
  max-width: 100%;
  width: 240px !important;
  word-break: break-word !important;
  white-space: normal !important;
}

.nav-mobile .navbar-nav .nav-item .nav-link i {
  float: right;
}

.nav-mobile .navbar-nav > .nav-item > .dropdown-menu {
  position: relative !important;
  width: 100%;
  transform: none !important;
  background-color: transparent !important;
  border: 0 !important;
  padding-top: 0;
  margin-top: 8px;
  margin-left: 0;
}

.nav-mobile .navbar-nav > .nav-item > .dropdown-menu > .dropdown-item {
  color: #333;
  background-color: transparent !important;
  font-size: 15px;
  line-height: 24px;
}

.nav-mobile .navbar-nav .dropdown-toggle::after {
  display: none !important;
}

.nav-mobile .profile-dropdown .dropdown-menu {
  background-color: transparent !important;
}

.nav-mobile .profile-dropdown .dropdown-menu > li > a {
  color: #333 !important;
  font-size: 14px !important;
  background-color: transparent !important;
}

.icon-mobile-lang {
  float: left !important;
  margin-right: 8px;
}

.dropdown-mobile-languages {
  margin-top: 15px;
  text-align: left;
}

.dropdown-mobile-languages .dropdown-menu {
  margin: 0 !important;
  padding-left: 0 !important;
  left: 40px;
}

.dropdown-mobile-languages .dropdown-menu a {
  text-transform: none !important;
}

.dropdown-mobile-languages a i {
  margin-right: 8px;
}

.mobile-mode-switcher {
  text-align: left;
  margin-top: 15px;
}

.btn-switch-mode-mobile {
  padding: 6px 12px;
  background-color: #f6f6f6 !important;
  border: 1px solid #ebebeb;
  border-radius: 6px;
}

.btn-switch-mode-mobile svg {
  display: inline-block !important;
  vertical-align: middle !important;
  position: relative;
  top: -1px !important;
}

.mobile-search-form {
  display: none;
  position: absolute !important;
  z-index: 9999 !important;
  width: 100%;
  background-color: #fff;
  padding: 5px;
  padding-bottom: 10px;
}

.open-search {
  display: block !important;
}

#main {
  min-height: 760px;
}

/* search box */
.search-icon {
  font-size: 17px !important;
  display: block;
  cursor: pointer;
  border-radius: 2px;
  text-align: center;
  margin-left: 0px !important;
  margin-top: -1px !important;
  padding-right: 5px !important;
}

.modal-search {
  display: none;
  background: #fff;
  position: absolute;
  width: 100%;
  z-index: 999999;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  transition: all 0.2s ease-in-out 0s;
  right: 15px;
  top: 100px;
}

.modal-search .container {
  position: relative;
}

.modal-search .s-close {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 13px;
  cursor: pointer;
  color: #999;
  box-shadow: none;
}

.mobile-search-icon {
  display: none;
}

.search-open .modal-search {
  display: block;
}

.modal-search .form-control:focus,
.modal-search .form-control {
  background: rgba(0, 0, 0, 0.9) none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  font-size: 14px;
  height: 64px;
  padding: 5px 20px;
  width: 40%;
  top: 0;
  right: 0;
  transition: all 0.2s ease-in-out 0s;
  position: absolute;
  border-radius: 0 !important;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
}

.search-active .modal-search {
  box-shadow: none;
  -webkit-box-shadow: none;
  border-color: #e0e0e0;
}

.social-mobile {
  display: none;
  border-bottom: 1px solid #eee !important;
  text-align: center;
  position: relative;
  min-height: 38px;
  width: 100%;
}

.social-mobile ul {
  margin: 0;
  padding: 0;
}

.social-mobile ul li {
  float: left;
  padding-bottom: 0;
}

/* ============ 3. Slider ============ */
.slider-container {
  width: 100%;
  max-width: 100%;
  display: block;
  position: relative;
  overflow-x: hidden !important;
}

.home-slider-fixer {
  width: 100%;
  max-width: 100%;
  display: block;
  position: relative;
}

.home-slider-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  display: block;
}

.home-slider-fixer .col-sl {
  width: 25%;
  height: auto;
  max-width: 100%;
  display: block;
  float: left;
}

.home-slider-fixer .col-sl img {
  width: 100% !important;
  max-width: 100% !important;
  height: auto !important;
  display: block;
  float: left;
}

.home-slider {
  width: 100%;
}

.home-slider-item {
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
  outline: none !important;
}

.home-slider-item .a-slider {
  position: relative;
  display: block;
  width: 100%;
  max-width: 100%;
}

.home-slider-item .img-slider {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.home-slider-item::after {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.77));
  width: 100%;
  height: 50%;
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  pointer-events: none;
}

.home-slider-item .item-info {
  width: 100%;
  bottom: 0;
  left: 0;
  position: absolute;
  padding: 15px;
  text-align: center;
  z-index: 10;
  cursor: pointer;
  visibility: hidden;
}

.slick-initialized .item-info {
  visibility: visible !important;
}

.home-slider-item .item-info .a-slider-category {
  color: #fff;
}

.home-slider-item .item-info .label-slider-category {
  position: relative;
  font-weight: 400;
  border-radius: 2px;
  cursor: pointer;
}

.home-slider-item .item-info .title {
  display: block;
  line-height: 28px;
  text-shadow: 0 0.5px 0.5px rgba(30, 30, 30, 0.4);
  font-size: 21px;
  font-weight: 400;
  margin: 10px 0 5px 0;
}

.home-slider-item .item-info .title a {
  color: #fff;
}

.slider-nav {
  width: 100%;
  height: 48px;
  position: absolute;
  opacity: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  pointer-events: none;
  -moz-transition: 0.2s;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.home-slider-container:hover .slider-nav {
  opacity: 1;
}

.slider-nav .prev {
  position: absolute;
  left: 0;
  height: 48px;
  line-height: 48px;
  text-align: center;
  width: 48px;
  border: 0 !important;
  padding: 2px !important;
  pointer-events: auto;
  margin-left: 10px;
  background-color: transparent !important;
}

.slider-nav .next {
  position: absolute;
  right: 0;
  height: 48px;
  line-height: 48px;
  text-align: center;
  width: 48px;
  border: 0 !important;
  padding: 2px !important;
  pointer-events: auto;
  margin-right: 10px;
  background-color: transparent !important;
}

.slider-nav .next i,
.slider-nav .prev i {
  display: inline-block;
  position: relative;
  background-color: #010101;
  border-radius: 2px;
  color: #fafafa;
  font-size: 18px;
  font-weight: bold;
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 40px;
  opacity: 0.6;
}

.external-image-container {
  width: 100%;
  height: auto;
  display: block;
  position: relative;
  overflow: hidden;
}

.external-image-container .img-external {
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  max-width: 120%;
  max-height: 120%;
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
}

.post-icon {
  position: absolute !important;
  right: 0 !important;
  left: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  margin: auto !important;
  background: rgba(0, 0, 0, 0.3) !important;
  border-radius: 100% !important;
  z-index: 10 !important;
  pointer-events: none !important;
  float: none !important;
}

.post-icon-lg {
  width: 48px !important;
  height: 48px !important;
}

.post-icon-md {
  width: 42px !important;
  height: 42px !important;
}

.post-icon-sm {
  width: 24px !important;
  height: 24px !important;
}

/* Slider*/
.home-slider-boxed-container {
  position: relative;
  margin-bottom: 30px;
}

.home-slider-boxed-fixer {
  position: relative;
}

.home-slider-boxed {
  width: 100%;
  max-width: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.home-slider-boxed-container:hover .slider-nav {
  opacity: 1;
}

.home-slider-boxed-item {
  position: relative;
}

.home-slider-boxed-fixer .img-slider-second {
  width: 100% !important;
}

.home-slider-boxed-item::after {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.77));
  width: 100%;
  height: 50%;
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  pointer-events: none;
}

.home-slider-boxed-item .item-info {
  width: 100%;
  bottom: 0;
  left: 0;
  position: absolute;
  padding: 30px 30px 25px 30px;
  z-index: 10;
  cursor: pointer;
}

.home-slider-boxed-item .item-info .a-slider-category {
  color: #fff;
}

.home-slider-boxed-item .item-info .label-slider-category {
  font-weight: 400;
  border-radius: 1px;
  padding: 3px 8px;
}

.home-slider-boxed-item .item-info .title {
  display: block;
  line-height: 28px;
  text-shadow: 0 0.5px 0.5px rgba(30, 30, 30, 0.4);
  font-size: 22px;
  font-weight: 400;
  margin: 15px 0;
}

.home-slider-boxed-item .item-info .title a {
  color: #fff;
}

.home-slider-boxed-item .post-meta {
  line-height: 12px;
}

.slider-container .post-meta .post-meta-inner span,
.slider-container .post-meta .post-meta-inner a {
  color: #b0b0b0 !important;
}

.home-slider-boxed-item .slider-fixer {
  position: relative;
  display: block;
  width: 100%;
  max-width: 100%;
}

.home-slider-boxed-item .img-slider {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

/* ============ 4. Post ============ */
.index-posts {
  display: block;
  width: 100%;
  float: left;
  position: relative;
}

.posts {
  display: block;
  width: 100%;
  position: relative;
}

.post-item {
  width: 100%;
  height: auto;
  float: left;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.post-item:last-child {
  border-bottom: 0;
}

.post-item .post-image {
  width: 100%;
  height: 415px;
  background-color: #fafafa;
  float: left;
  position: relative;
  margin-top: 24px;
}

.post-item .post-image img {
  margin: 0 auto;
}

.post-item .post-footer {
  width: 100%;
  float: left;
  position: relative;
}

.post-item .post-category {
  border-bottom: 1px solid #222 !important;
}

.post-meta {
  display: block;
  width: 100%;
  position: relative;
  line-height: 20px;
}

.post-meta .post-meta-inner {
  position: relative;
  display: inline-block;
  margin: 0 !important;
}

.post-meta .post-meta-inner span,
.post-meta .post-meta-inner a {
  float: left;
  margin-right: 10px;
  color: #b0b0b0 !important;
  display: block;
  font-size: 12px;
}

.post-meta .post-meta-inner a {
  font-weight: 600;
  margin-right: 10px;
}

.label-post-category {
  display: inline-block;
  position: absolute;
  left: 8px;
  background-color: #d8a61b;
  top: 8px;
  font-size: 11px;
  font-weight: 400;
  color: rgb(255, 255, 255);
  height: 1.8em;
  line-height: 1.8;
  padding: 0 12px;
  border-radius: 2px;
  cursor: pointer;
  pointer-events: auto;
  white-space: nowrap;
  z-index: 100;
}

.default-post-label-category {
  text-align: center;
}

.default-post-label-category .label-post-category {
  display: inline-block;
  position: relative;
  left: auto;
  top: auto;
  font-size: 11px;
  font-weight: 400;
  color: #fff;
  height: 1.8em;
  line-height: 1.8;
  padding: 0 12px;
  border-radius: 2px;
  cursor: pointer;
  pointer-events: auto;
  white-space: nowrap;
  z-index: 100;
  margin-top: 15px;
}

.post-item .post-footer .title {
  font-size: 22px;
  font-weight: 500;
  margin: 10px 0;
  line-height: 28px;
}

.post-item .post-footer .summary {
  font-size: 13px;
  font-weight: 400;
  line-height: 26px;
  color: #888;
  margin-top: 2px;
}

.post-item .post-footer .post-buttons {
  font-size: 12px;
  color: #999;
  display: block;
  width: 100%;
  height: 30px;
  line-height: 30px;
}

.post-item .post-footer .post-buttons a {
  position: relative;
  line-height: 20px;
  top: 14px;
}

.post-item .post-footer .post-buttons .post-social {
  padding: 0;
  margin: 0;
  padding: 5px 12px 6px 0;
}

.post-item .post-footer .post-buttons .post-social li {
  list-style: none;
  float: left;
  margin-right: 20px;
}

.post-item .post-footer .post-buttons .post-social li a {
  font-size: 14px;
}

.post-item .post-footer .post-buttons .post-social li .facebook:hover {
  color: #3b5998 !important;
}

.post-item .post-footer .post-buttons .post-social li .twitter:hover {
  color: #1da1f2 !important;
}

.post-item .post-footer .post-buttons .post-social li .google:hover {
  color: #dc4a38 !important;
}

.post-item .post-footer .post-buttons .post-social li .instgram:hover {
  color: #d02e95 !important;
}

.post-item .post-footer .post-buttons .post-social li .linkedin:hover {
  color: #0177b5 !important;
}

.read-more {
  font-size: 13px;
  font-weight: 400;
}

.read-more i {
  margin-left: 10px;
  -moz-transition: 0.3s;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.read-more:hover .read-more-i {
  margin-left: 25px;
  margin-right: 5px;
}

.post-item-horizontal {
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 30px;
  display: table;
}

.post-item-horizontal .item-image {
  width: 47.6667%;
  height: 165px;
  display: table-cell;
  vertical-align: top;
  position: relative;
  background-color: #fafafa;
}

.post-item-horizontal .item-content {
  width: 52.333%;
  display: table-cell;
  vertical-align: top;
  padding-left: 30px;
  position: relative;
}

.post-item-horizontal .category-label {
  left: 23px;
  position: absolute;
  top: 8px;
  z-index: 10;
}

.post-item-horizontal .title {
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
  margin: 0 0 10px 0;
}

.post-item-horizontal .title a {
  display: block;
}

.post-item-horizontal .summary {
  display: block;
  float: left;
  color: #888;
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  margin-top: 10px;
}

.post-item-horizontal .post-buttons {
  display: block;
  float: left;
  width: 100%;
  position: relative;
  bottom: 0;
  right: 0;
  padding-right: 15px;
}

.post-item-boxed {
  margin-bottom: 15px;
}

.post-item-boxed .item-image {
  width: 100%;
  height: 239.8px;
  display: block;
  background-color: #fafafa;
}

.profile-page .post-item-boxed .item-image {
  height: 272.283px;
}

.post-item-boxed img {
  width: 100%;
}

.post-item-boxed .item-content {
  display: block;
  float: left;
  width: 100%;
  padding: 20px 0;
}

.post-item-boxed .category-label {
  left: 23px;
  position: absolute;
  top: 8px;
  z-index: 10;
}

.post-item-boxed .title {
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
  margin: 0 0 10px 0;
}

.post-item-boxed .title a {
  display: block;
}

.post-item-boxed .summary {
  display: block;
  float: left;
  color: #888;
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  margin-top: 10px;
}

.post-item-boxed .post-buttons {
  display: block;
  float: left;
  width: 100%;
  text-align: right;
  margin-top: 10px;
}

.author-profile {
  display: block;
  float: left;
  width: 100%;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #f0f0f0;
}

.author-profile img {
  float: left;
  margin-right: 15px;
  width: 120px;
  height: 120px;
  border-radius: 1px;
}

.author-profile .title {
  display: inline-block;
  margin: 0 !important;
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
}

.post-count {
  font-size: 13px;
}

/* ============ 5. Sidebar ============ */
.sidebar {
  height: auto;
  width: 100%;
  display: block;
  position: relative;
}

.sidebar-widget {
  width: 100%;
  height: auto;
  float: left;
  margin-bottom: 30px;
}

.sidebar-widget .widget-body {
  padding-top: 15px;
}

.widget-title {
  width: 100%;
  display: block;
  padding: 10px 0;
}

.widget-title .title {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 10px;
  border-bottom: 2px solid #f1f1f1;
}

.widget-title .title::after {
  content: "";
  position: absolute;
  left: 0;
  width: 31%;
  height: 2px;
  bottom: -2px;
  max-width: 112px;
}

.widget-popular-posts-title {
  padding-top: 0;
}

.widget-list {
  padding: 0;
  margin: 0;
  position: relative;
}

.w-popular-list li {
  list-style: none;
  position: relative;
  width: 100%;
  display: table;
  float: left;
  margin-bottom: 15px;
}

.w-popular-list li .left {
  width: 100px;
  height: 75px;
  overflow: hidden;
  display: table-cell;
  vertical-align: top;
  background-color: #fafafa;
}

.w-popular-list li .left .external-post-image {
  width: 100px;
  height: 75px;
  background-color: #fafafa;
  overflow: hidden;
  position: relative;
}

.w-popular-list li .right {
  display: table-cell;
  vertical-align: top;
  padding-left: 15px;
}

.w-popular-list li .img-responsive {
  position: relative;
  height: 75px;
}

.w-popular-list li .title {
  margin: 0;
  margin-bottom: 5px;
  font-weight: 500;
  line-height: 21px;
  font-size: 14px;
}

.w-popular-list li .title a {
  display: list-item;
}

.widget-list li .w-meta span {
  font-size: 11px !important;
  color: #999 !important;
  margin-right: 8px;
}

.widget-list li .w-meta a {
  color: #999;
  font-size: 11px;
  font-weight: 600;
  margin-right: 8px;
}

.w-our-picks-list {
  padding: 0;
  margin: 0;
  position: relative;
}

.w-our-picks-list li {
  list-style: none;
  position: relative;
  width: 100%;
  display: block;
  float: left;
  margin-bottom: 25px;
}

.w-our-picks-list li .post-image {
  display: block;
  width: 100%;
  height: 199.2px;
  position: relative;
  float: left;
  background-color: #fafafa;
}

.w-our-picks-list .external-post-image {
  width: 100%;
  height: 199.2px;
  overflow: hidden;
}

.w-our-picks-list li img {
  width: 100%;
  float: left;
  margin-right: 15px;
  position: relative;
}

.w-our-picks-list li .title {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin: 10px 0;
  float: left;
}

.w-our-picks-list .post-meta {
  float: left;
}

.w-category-list li {
  margin-bottom: 7px;
  padding-bottom: 7px;
  list-style: none;
  position: relative;
  width: 100%;
  display: block;
  float: left;
  border-bottom: 1px dashed rgba(168, 166, 166, 0.2);
}

.w-category-list li:last-child {
  border-bottom: 0;
}

.w-category-list li a {
  font-weight: 400;
  line-height: 20px;
  font-size: 15px;
  margin: 0;
  margin-right: 10px;
}

.w-category-list li span {
  color: #999;
  font-size: 13px;
  position: relative;
}

.categories-sub-link {
  padding-left: 20px;
}

/*random slider*/
.slider-container {
  overflow: hidden !important;
}

.random-slider {
  position: relative;
}

.random-slider-fixer {
  position: relative;
}

.random-slider-fixer img {
  width: 100%;
  max-width: 100%;
  display: block;
}

.random-slider-container {
  display: block;
  width: 100%;
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.random-slider .home-slider-boxed-item::after {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  width: 100%;
  height: 50%;
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  pointer-events: none;
}

.random-slider .home-slider-boxed-item .item-info {
  padding: 15px;
}

.random-slider .home-slider-boxed-item .item-info .title {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 5px;
}

.random-slider-nav {
  top: 8px !important;
  bottom: auto !important;
  opacity: 1 !important;
  display: block !important;
}

.random-slider-nav .prev {
  right: 50px;
  left: auto !important;
  margin-left: 0 !important;
  margin-right: 10px !important;
  text-align: right !important;
}

.random-slider-nav .next {
  margin-right: 8px !important;
  text-align: left !important;
}

.post-details-slider {
  opacity: 0;
}

.post-details-slider .home-slider-boxed-item::after {
  display: none !important;
}

/*tags*/
.w-tag-list {
}

.w-tag-list li {
  list-style: none;
  float: left;
  margin-right: 8px;
  margin-bottom: 8px;
}

.w-tag-list li a {
  background-color: #f4f4f4;
  padding: 6px 10px;
  font-size: 13px;
  display: block;
  color: #888;
  border-radius: 2px;
  line-height: 20px;
}

.w-tag-list li a:hover {
  color: #fff !important;
}

.content {
  display: block;
  height: auto;
  min-height: 450px;
}

.breadcrumb > li + li::before {
  padding: 0 2px !important;
}

.page-breadcrumb {
  margin-top: 15px;
}

.page-breadcrumb .breadcrumb {
  background-color: transparent;
}

.breadcrumb {
  margin-bottom: 15px;
}

.page-breadcrumb .breadcrumb .breadcrumb-item a {
  color: #888;
}

.page-breadcrumb .breadcrumb .breadcrumb-item {
  font-size: 12px;
  color: #666;
}

/*RSS page*/
.page-rss {
  min-height: 450px;
}

.rss-title {
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 15px;
  margin-bottom: 15px;
  font-size: 20px;
}

.rss-item {
  display: table;
  width: 100%;
  margin-bottom: 10px;
}

.rss-item .left {
  display: table-cell;
  width: 250px;
}

.rss-item .right {
  display: table-cell;
}

.rss-item .right p {
  word-break: break-all;
}

.rss-content {
  margin-top: 60px;
}

/* ============ 6.Post Detail ============ */
.post-content {
  display: block;
  width: 100%;
  float: left;
}

.post-avatar {
  border-radius: 100%;
  float: left;
  height: 30px;
  margin-right: 10px;
  width: 30px;
  position: relative;
}

.post-content .post-image {
  width: 100%;
  float: left;
}

.post-content .post-meta {
  line-height: 30px;
}

.post-content .post-title {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}

.post-content .post-title .title {
  font-size: 28px;
  line-height: 34px;
  font-weight: 600;
}

.post-content .post-title .title {
  font-size: 36px;
  line-height: 44px;
  font-weight: 400;
  margin: 0;
}

.post-content .post-meta {
  width: 100%;
  float: left;
  display: block;
  margin-bottom: 15px;
}

.post-content .post-meta span {
  font-size: 12px;
  color: #b0b0b0;
  margin-right: 10px;
  font-weight: 400;
}

.post-content .post-meta span a {
  font-weight: 400;
  margin: 0;
}

.post-content .post-meta a {
  font-size: 12px;
  color: #999;
  margin-right: 10px;
}

.post-content .post-meta form {
  float: right;
}

.post-content .post-summary {
  width: 100%;
  float: left;
  display: block;
  margin-bottom: 10px;
}

.post-content .post-summary h2 {
  margin: 0 !important;
  font-size: 15px;
  line-height: 26px;
  font-weight: 600;
}

.post-content .post-video {
  width: 100%;
  float: left;
  display: block;
  margin-bottom: 15px;
}

.add-to-reading-list {
  color: #4cae4c !important;
  font-size: 12px !important;
  border: 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  outline: none !important;
}

.delete-from-reading-list {
  color: #c52d2f !important;
  font-size: 12px !important;
  line-height: 30px;
  border: 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  outline: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.post-content .post-text {
  width: 100%;
  float: left;
  font-size: 14px;
  font-variant-ligatures: no-common-ligatures;
  line-height: 28px;
  display: table-cell;
  font-weight: 400;
  margin-top: 20px;
}

.post-content .post-text p {
  min-height: 1px;
  position: relative;
  width: 100%;
  overflow-wrap: break-word;
}

.post-content .post-text a:hover {
  text-decoration: underline;
}

.post-content .post-text img {
  position: relative !important;
  display: block;
  max-width: 100% !important;
  height: auto !important;
}

.post-text iframe,
.post-text object,
.post-text embed {
  display: block;
  max-width: 100% !important;
  border: 0 !important;
}

.post-text .post-files {
  margin: 15px 0;
}

.post-text .post-files .title {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  line-height: 28px;
}

.post-text .post-files .file {
  margin-top: 5px;
}

.post-text .post-files .file button {
  background-color: transparent !important;
  padding: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
}

.post-text .post-files .file button:hover {
  text-decoration: underline;
}

.post-text .post-files .file i {
  margin-right: 8px;
}

.post-content .post-tags {
  width: 100%;
  float: left;
  margin-top: 15px;
  margin-bottom: 30px;
}

.post-content .tags-title {
  float: left;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  margin-right: 15px;
  line-height: 28px;
}

.post-content .post-tags .tag-list {
  float: left;
  margin: 0;
  padding: 0;
}

.post-content .post-tags .tag-list li {
  list-style: none;
  display: inline-block;
  margin-right: 7px;
  margin-bottom: 7px;
}

.post-content .post-tags .tag-list li a {
  border: 2px solid #f4f4f4;
  border-radius: 2px !important;
  color: #888;
  display: block;
  font-size: 12px;
  padding: 3px 7px;
  line-height: 20px;
}

.post-content .post-tags .tag-list li a:hover {
  color: #fff !important;
}

.post-content .post-share {
  width: 100%;
  float: left;
  margin-top: 10px;
  margin-bottom: 30px;
}

.post-content .post-share .btn-share {
  display: block;
  float: left;
  border: none;
  background-color: #ececec;
  text-decoration: none;
  font-size: 13px;
  color: #fff;
  margin-right: 10px;
  width: 110px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin-bottom: 10px;
  border-radius: 2px;
}

.post-content .post-share .btn-share i {
  margin-right: 4px;
  font-size: 16px;
}

.post-content .post-share .btn-share:hover {
  color: #efefef;
}

.post-content .post-share .facebook {
  background-color: #3b5998;
}

.post-content .post-share .gplus {
  background-color: #dd4b39;
}

.post-content .post-share .twitter {
  background-color: #55acee;
}

.post-content .post-share .pinterest {
  background-color: #cc2127;
}

.post-content .post-share .linkedin {
  background-color: #0077b5;
}

.post-content .post-share .whatsapp {
  background: #2cb742;
}

.post-content .post-text table {
  border-collapse: collapse;
  overflow: hidden;
  width: 100%;
  max-width: 100% !important;
  margin: 0 auto;
  position: relative;
  border: 1px solid #f2f2f2;
  font-size: 14px;
}

.post-content .post-text table th {
  padding: 6px 10px;
  border: 0;
  border-bottom: 2px solid #f2f2f2;
  font-weight: 700;
}

.post-content .post-text table tr {
  border-bottom: 1px solid #f2f2f2;
}

.post-content .post-text table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.post-content .post-text table td {
  padding: 6px 12px;
  border: 0 !important;
}

.post-content .post-text table tr,
.post-content .post-text table td {
  height: auto !important;
}

.about-author {
  display: table;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 30px;
  width: 100%;
}

.about-author-page {
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #f0f0f0;
}

.about-author-left {
  display: table-cell;
  width: 130px;
  vertical-align: top;
}

.about-author-right {
  display: table-cell;
  vertical-align: top;
}

.about-author-row {
  width: 100%;
  display: block;
  float: left;
}

.about-author-right .p-about-author {
  margin-bottom: 10px;
  line-height: 20px;
  min-height: 20px;
  display: block;
}

.about-author-right .author-link {
  float: left;
}

.about-author-right .author-social-cnt {
  display: block;
  width: 100%;
  float: left;
  margin-top: 10px;
}

.about-author-right .author-social {
  margin: 0;
  padding: 0;
}

.about-author-right .author-social li {
  list-style: none;
  float: left;
  margin-right: 30px;
  margin-bottom: 15px;
}

.img-author {
  width: 110px;
  height: 110px;
}

.profile-post-author .about-author {
  margin-top: 0;
  margin-bottom: 30px;
  border-top: none;
  padding-top: 0;
}

.optional-url-cnt {
  display: block;
  float: left;
  width: 100%;
  margin-top: 30px;
  text-align: right;
}

.optional-url-cnt a {
  text-decoration: none !important;
}

/*Profile*/
.profile-tabs .nav .nav-item {
  width: 100%;
  text-align: left;
  margin-bottom: 2px !important;
}

.profile-tabs .nav .nav-item .nav-link {
  padding: 11px;
  border-radius: 3px;
  color: #222;
}

.profile-tabs .nav .nav-item .nav-link i {
  margin-right: 8px;
}

.profile-tabs .nav .nav-item .nav-link .count {
  float: right;
}

.profile-tabs .nav .nav-link:hover {
  background-color: #f2f2f2;
}

.profile-tabs .nav .active .nav-link {
  background-color: #f2f2f2;
}

.profile-page-top {
  width: 100%;
  display: block;
  position: relative;
  float: left;
  margin-bottom: 60px;
}

.profile-details {
  width: 100%;
  display: table;
  position: relative;
  float: left;
}

.profile-details .left {
  display: table-cell;
  vertical-align: top;
  text-align: left;
  width: 200px;
  padding-right: 30px;
}

.profile-details .right {
  display: table-cell;
  vertical-align: top;
}

.profile-details .img-profile {
  width: 200px;
  height: 200px;
  border-radius: 3px;
}

.profile-details .p-last-seen {
  margin: 0;
  text-align: left;
  margin-bottom: 8px;
  color: #888;
}

.last-seen {
  font-size: 12px;
  color: #888;
}

.last-seen i {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  color: #cfcfcf;
}

.last-seen-online i {
  color: #85cc84;
}

.profile-details .username {
  font-size: 26px;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 5px;
  margin-top: 5px;
  width: auto;
  display: inline-block;
  float: left;
}

.profile-details .description {
  line-height: 24px;
  color: #555;
  margin-bottom: 10px;
}

.profile-details .user-contact .info {
  color: #777;
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid #e1e3df;
}

.profile-details .user-contact .info:last-child {
  border: 0;
}

.profile-details .user-contact .info i {
  margin-right: 10px;
}

.profile-buttons {
  margin-top: 20px;
  text-align: left;
  line-height: 34px;
}

.profile-buttons .btn-follow {
  float: left;
  margin-right: 30px;
}

.profile-buttons ul {
  float: left;
  margin: 0;
  padding: 0;
}

.profile-buttons ul li {
  display: inline-block;
  margin-left: 5px;
}

.profile-buttons ul li:first-child {
  margin-left: 0;
}

.profile-buttons ul li a {
  display: block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  color: #777;
  font-size: 15px;
  border: 1px solid #bebebe;
  border-radius: 100%;
}

.profile-buttons ul li a:hover {
  color: #fff;
}

.btn-follow i {
  margin-right: 10px;
}

.widget-followers {
  width: 100%;
  display: block;
  float: left;
  position: relative;
}

.widget-followers {
  width: 100%;
  display: block;
  float: left;
  position: relative;
  margin-bottom: 30px;
}

.widget-followers .widget-head {
  width: 100%;
  display: block;
  float: left;
  position: relative;
  border-bottom: 1px solid #ebebeb;
}

.widget-followers .widget-head .title {
  font-size: 14px;
  margin-top: 0;
  font-weight: 600;
}

.widget-followers .widget-body {
  width: 100%;
  display: block;
  float: left;
  position: relative;
  padding: 15px 0;
}

.widget-followers .widget-content {
  width: 100%;
  display: block;
  float: left;
  position: relative;
  max-height: 260px;
  overflow: hidden;
}

.img-follower {
  width: 46px;
  height: 46px;
  float: left;
  margin-right: 6px;
  margin-bottom: 6px;
  overflow: hidden;
}

.img-follower img {
  width: 46px;
  height: 46px;
}

.btn-profile-file-upload {
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-profile-file-upload:hover,
.btn-profile-file-upload:focus,
.btn-profile-file-upload:active {
  background-color: #5a6268 !important;
  border-color: #5a6268 !important;
}

.btn-file-upload {
  position: relative;
  font-size: 13px !important;
  padding: 4px 16px !important;
  overflow: hidden !important;
  margin-right: 15px;
}

.btn-file-upload input {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  filter: alpha(opacity=0);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  opacity: 0;
  background-color: transparent;
  color: transparent;
  cursor: pointer;
}

#MultidvPreview img {
  width: auto;
  max-width: 100%;
  height: 200px;
  float: left !important;
}

.form-avatar {
  max-width: 180px;
  max-height: 180px;
  border-radius: 3px;
}

/*related posts*/
.related-posts {
  width: 100%;
  float: left;
  margin-top: 30px;
  margin-bottom: 30px;
}

.related-posts .related-post-title {
  display: block;
  padding: 10px 0;
  width: 100%;
}

.related-posts .related-post-title .title {
  position: relative;
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  text-transform: uppercase;
  padding-bottom: 10px;
  border-bottom: 2px solid #f1f1f1;
}

.related-posts .related-post-title .title::after {
  content: "";
  position: absolute;
  left: 0;
  width: 31%;
  height: 2px;
  bottom: -2px;
  max-width: 112px;
}

.related-posts .related-posts-row {
  margin-left: -10px;
  margin-right: -10px;
}

.related-posts .related-posts-col {
  padding-left: 10px;
  padding-right: 10px;
}

.related-posts .post-list {
  margin: 0;
  padding: 0;
  margin-top: 20px;
}

.related-posts .post-list li {
  list-style: none;
}

.related-posts .post-list li .external-post-image {
  width: 100%;
  height: 157.65px;
  overflow: hidden;
}

.related-posts .post-list li .title {
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 5px;
  text-transform: none;
  margin-top: 15px;
}

.related-posts .post-list li .title a {
  display: list-item;
}

.related-posts .post-list li .img-responsive {
  height: 157.65px;
}

/*Comments*/
.comment-section {
  width: 100%;
  position: relative;
  display: block;
  float: left;
}

.comment-section .nav-tabs {
  border-bottom: 2px solid #dee2e6;
}

.comment-section .nav-tabs li a {
  border: 0 !important;
  background-color: transparent !important;
  line-height: 24px;
  font-weight: 600;
  text-align: left;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 10px;
  border-bottom: 2px solid transparent;
  padding: 10px 30px;
  color: #333 !important;
}

.comment-section .nav-tabs .active {
  border: 0;
  margin-bottom: -2px;
}

.comment-section .tab-content .tab-pane {
  padding: 20px 0;
  width: 100%;
}

.comment-section .comments {
  float: left;
  display: block;
  width: 100%;
}

.comment-section .comments .comment-list {
  padding: 0;
  margin: 0;
  float: left;
  display: block;
  width: 100%;
}

.comment-section .comments .comment-list li {
  width: 100%;
  list-style: none;
  display: table;
  margin-bottom: 20px;
}

.comment-section .comments .comment-list li .left {
  display: table-cell;
  width: 65px;
  height: auto;
  vertical-align: top;
  text-align: left;
}

.comment-section .comments .comment-list li .left img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.comment-section .comments .comment-list li .right {
  display: table-cell;
  vertical-align: top;
}

.comment-section .comments .comment-list li .right .row-custom {
  margin-bottom: 5px;
}

.comment-section .comments .comment-list li .right .username {
  font-weight: 600;
  float: left;
  display: block;
}

.comment-section .comments .comment-list li .right .date {
  font-size: 12px;
  color: #999;
  float: left;
  margin-right: 15px;
}

.comment-section .comments .comment-list li .right .btn-reply {
  float: left;
  margin-right: 15px;
  font-size: 12px;
  color: #999;
}

.comment-section .comments .comment-total {
  margin-bottom: 30px;
  margin-top: 30px;
}

.comment-section .comments .label-comment {
  font-size: 15px;
  font-weight: 600;
  display: block;
  float: left;
  margin-right: 10px;
  margin-bottom: 0;
  line-height: 25px;
}

.comment-section .comments .comment-total span {
  display: block;
  font-size: 15px;
  float: left;
  line-height: 25px;
}

/*subcomments*/
.row-sub-comment {
  margin: 0 !important;
  padding: 0 !important;
}

.sub-comment-form {
  margin-top: 15px;
  padding: 15px;
  border-radius: 0.1875rem;
}

.sub-comment-form-registered {
  margin-top: 15px;
}

.row-sub-comment .form-textarea {
  min-height: 80px;
}

.row-sub-comment .comment-list {
  margin-top: 10px !important;
  padding-top: 10px !important;
}

.row-sub-comment .comment-list li {
  margin-bottom: 15px !important;
}

.btn-load-more {
  background: #fff;
  border: 1px solid #e5e5e5;
  display: block;
  font-size: 13px;
  line-height: 20px;
  margin: 0 auto;
  padding: 14px 10px;
  text-align: center;
  transition: all 0.25s ease 0s;
  color: #333;
  border-radius: 0.1875rem;
  width: 100%;
  max-width: 100%;
  font-weight: 600;
  cursor: pointer;
}

.btn-delete-comment {
  font-size: 12px;
  color: #999;
}

.message-comment-result {
  margin: 10px 0 !important;
  min-height: 22px;
}

.comment-success-message {
  position: relative;
  color: #00a65a;
  margin: 0 !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*Spinner*/
.load-more-spinner {
  display: none;
}

.spinner {
  margin: 0 auto;
  width: 70px;
  text-align: center;
  padding: 10px 0;
  margin-bottom: 15px;
  margin-top: 15px;
}

.spinner > div {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* ============ 7. Pages ============ */
.page-about {
  width: 100%;
  float: left;
  font-size: 14px;
  font-variant-ligatures: no-common-ligatures;
  line-height: 26px;
}

.page-about p {
  overflow-wrap: break-word;
}

.page-res img {
  position: relative !important;
  max-width: 100% !important;
  height: auto !important;
}

.page-res iframe,
.page-res object,
.page-res embed {
  max-width: 100% !important;
  border: 0 !important;
}

.page-title {
  font-size: 24px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 25px;
  margin-top: 0;
}

.page-contact {
  width: 100%;
  float: left;
  font-size: 14px;
  font-variant-ligatures: no-common-ligatures;
  line-height: 26px;
}

.page-contact .form-control {
  color: #494949;
  font-size: 14px;
  line-height: 18px;
  height: 48px;
  padding: 10px 20px;
  box-shadow: none !important;
  border: 1px solid #dfe0e6;
  outline: none !important;
  border-radius: 3px;
}

.page-contact textarea {
  min-height: 130px;
  resize: vertical;
  line-height: 18px !important;
}

.page-contact .form-control:focus {
  outline: 0 none;
  -moz-transition: 0.2s;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.contact-leave-message {
  font-size: 19px;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 15px;
}

.row-contact-text {
  margin-bottom: 30px;
}

.contact-item {
  margin-bottom: 10px;
  display: block;
  float: left;
  width: 100%;
}

.contact-item .contact-icon {
  max-width: 30px;
  padding-right: 0;
  text-align: center;
  font-size: 15px;
  color: #444;
  margin-right: 10px;
}

.contact-item .contact-icon i {
  background-color: #f6f6f6;
  border-radius: 2px;
  color: #666;
  display: block;
  font-size: 14px;
  text-align: center;
  width: 24px;
  height: 24px;
  line-height: 24px;
  margin-top: 2px;
}

/*page login*/
.page-login {
  padding: 60px 0;
}

.link-forget {
  color: #333;
}

.page-login .form-group .glyphicon {
  color: #949494;
  line-height: 46px;
  margin-right: 10px;
}

.btn-error-back {
  font-size: 13px;
  margin-top: 30px;
  border-radius: 2px;
}

.page-confirm {
  width: 400px;
  height: auto;
  margin: 0 auto;
  text-align: center;
  padding-top: 120px;
  max-width: 100%;
}

.page-confirm .p-order-number {
  font-weight: bold;
  font-size: 18px;
}

.page-confirm .p-complete-payment {
  margin-top: 60px;
  text-align: center;
}

.page-confirm .bank-account-container {
  text-align: center;
}

.page-confirm .title {
  font-size: 18px;
  line-height: 24px;
}

.btn-social {
  width: 100%;
  display: block;
  position: relative;
  outline: 0 !important;
  box-shadow: none !important;
  padding: 11px 10px;
  padding-left: 54px;
  border-radius: 4px;
  text-align: left;
  margin-bottom: 5px;
  color: #fff !important;
  font-weight: 600 !important;
  line-height: 24px;
}

.btn-social svg {
  position: absolute;
  left: 15px;
}

.btn-social-facebook {
  background-color: #1877f2;
}

.btn-social-facebook:hover {
  background-color: #0665e0;
}

.btn-social-google {
  background-color: #eeeeee;
  color: #757575 !important;
}

.btn-social-google:hover {
  background-color: #e1e1e1;
}

.btn-social-modal i {
  width: 40px;
  margin-right: 10px;
}

.p-auth-modal {
  color: #777;
  text-align: center;
  margin-bottom: 10px;
}

.p-auth-modal-or {
  color: #777;
  text-align: center;
  margin-top: 15px;
}

/*Gallery*/
.page-gallery {
  min-height: 700px;
  padding-bottom: 100px;
}

.filters {
  width: auto;
  position: relative;
  display: block;
  margin-bottom: 30px;
  text-align: center;
}

.filters input {
  display: none;
}

.filters .btn {
  border: 0;
  color: #555 !important;
  box-shadow: none;
  font-size: 14px;
  line-height: 16px;
  margin: 5px 10px;
  padding: 6px 15px;
  -moz-transition: all 0.45s ease-in-out 0s;
  -webkit-transition: all 0.45s ease-in-out 0s;
  transition: all 0.45s ease-in-out 0s;
  background-color: #fff;
  box-shadow: none !important;
  position: relative;
  text-align: center;
  font-weight: 600;
  background-color: transparent !important;
}

.filters .active::after {
  bottom: -2px;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  width: 100%;
}

.filters .btn:last-child {
  margin-right: 0;
}

.filters .btn:focus:after,
.filters .btn:hover:after,
.filters .btn:active:after {
  bottom: -2px;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  width: 100%;
  -moz-transition: all 0.45s ease-in-out 0s;
  -webkit-transition: all 0.45s ease-in-out 0s;
  transition: all 0.45s ease-in-out 0s;
}

.gallery .gallery-item {
  position: relative;
  cursor: pointer;
  padding-left: 7.5px !important;
  padding-right: 7.5px !important;
  margin-bottom: 15px;
}

.gallery .gallery-item .item-inner {
  position: relative;
  display: block;
  overflow: hidden;
}

.gallery img {
  width: 100%;
  transition: all 0.5s;
  text-indent: -9999px;
}

.gallery .lightbox img {
  width: 100%;
  border-radius: 0;
  position: relative;
}

.gallery .lightbox:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
  content: "";
  transition: 0.4s;
}

.gallery .lightbox:hover:after,
.gallery .lightbox:hover:before {
  opacity: 0.5;
}

.gallery .gallery-item:hover img {
  transform: scale(1.1);
}

.row-masonry {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.gallery .gallery-item .caption {
  width: 100%;
  position: absolute;
  padding: 0;
  bottom: 0;
  left: 0;
  color: #fff;
  z-index: 10;
  font-size: 16px;
  text-align: center;
  padding: 8px;
}

.gallery .gallery-item .gallery-image-cover a::after {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.77));
  width: 100%;
  height: 50%;
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  pointer-events: none;
}

.gallery .gallery-item .caption .album-name {
  display: inline-block;
  width: auto;
  height: auto;
  padding: 5px 10px;
  border-radius: 3px;
}

.img-gallery-empty {
  width: 100%;
  height: 300px;
  background-color: #f8f8f8;
}

.gallery-category-title {
  font-size: 22px;
  line-height: 22px;
  font-weight: 500;
  margin-bottom: 30px;
  display: inline-block;
  width: auto;
  margin: 0 15px 30px 15px;
  padding: 10px 20px;
}

.gallery-links {
  font-size: 16px;
}

.gallery-links .gallery-next-link {
  margin-left: 60px;
}

/* ============ 8. Pagination ============ */
.pagination {
  float: right;
}

.pagination li {
  float: left;
}

.pagination li a {
  margin: 0 4px;
  padding: 8px 14px !important;
  min-height: 38px;
  min-width: 38px;
  border: 1px solid #eee;
  border-radius: 2px !important;
  color: #666 !important;
  text-align: center;
  transition: all 0.2s ease-in-out 0s;
  font-size: 14px;
  line-height: 20px;
}

.pagination li span {
  border-radius: 2px !important;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  background-color: #f6f6f6;
  border-color: #ddd;
}

/* ============ 9. Footer ============ */
#footer {
  background-color: #222;
  padding-top: 45px;
  margin-top: 100px;
  color: #9a9a9a;
}

.footer-widgets {
  padding-bottom: 10px;
}

.footer-widget {
  margin-bottom: 30px;
  float: left;
}

.footer-widget .title {
  text-transform: uppercase;
  padding-bottom: 8px;
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 500;
  position: relative;
}

.footer-widget .title-line {
  display: block;
  width: 60px;
  height: 3px;
  background-color: #333;
  margin-bottom: 20px;
}

.f-widget-about p {
  font-size: 14px;
  line-height: 24px;
}

.f-random-list {
  padding: 0;
  margin: 0;
  position: relative;
}

.f-random-list li {
  list-style: none;
  position: relative;
  width: 100%;
  display: table;
  float: left;
  margin-bottom: 15px;
  min-height: 75px;
}

.f-random-list li .left {
  width: 100px;
  height: 75px;
  overflow: hidden;
  display: table-cell;
  vertical-align: top;
  position: relative;
}

.f-random-list li .left .external-image-container {
  width: 100px;
  height: 75px;
  overflow: hidden;
}

.f-random-list li .left .external-post-image {
  width: 100px;
  height: 75px;
  overflow: hidden;
  position: relative;
}

.f-random-list li .left a {
  display: block;
  width: 100%;
}

.f-random-list li .right {
  display: table-cell;
  vertical-align: top;
  padding-left: 15px;
}

.f-random-list li .title {
  font-weight: 500;
  line-height: 21px;
  font-size: 14px;
  margin: 0;
  margin-bottom: 5px;
  text-transform: none;
}

.f-random-list li .title a {
  color: #9a9a9a;
  display: block;
}

.f-widget-follow ul {
  margin: 0;
  padding: 0;
}

.f-widget-follow ul li {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
}

.f-widget-follow ul li a {
  width: 38px;
  height: 38px;
  color: #ccc;
  font-size: 16px;
  line-height: 38px;
  text-align: center;
  display: block;
  border-radius: 2px;
  background-color: #333;
}

.f-widget-follow ul li a:hover {
  text-decoration: none;
}

.f-widget-follow ul li .facebook:hover {
  background-color: #3b5998;
  color: #fff !important;
}

.f-widget-follow ul li .twitter:hover {
  background-color: #1da1f2;
  color: #fff !important;
}

.f-widget-follow ul li .google:hover {
  background-color: #dc4a38;
  color: #fff !important;
}

.f-widget-follow ul li .instgram:hover {
  background-color: #d02e95;
  color: #fff !important;
}

.f-widget-follow ul li .linkedin:hover {
  background-color: #0177b5;
  color: #fff !important;
}

.f-widget-follow ul li .pinterest:hover {
  background-color: #bd081c;
  color: #fff !important;
}

.f-widget-follow ul li .vk:hover {
  background-color: #4c75a3;
  color: #fff !important;
}

.f-widget-follow ul li .telegram:hover {
  background-color: #0088cc;
  color: #fff !important;
}

.f-widget-follow ul li .youtube:hover {
  background-color: #ff0000;
  color: #fff !important;
}

.f-widget-follow ul li .rss:hover {
  background-color: #f8991d;
  color: #fff !important;
}

/*newsletter*/
.widget-newsletter {
  margin-top: 10px;
  margin-bottom: 20px;
}

.widget-newsletter input[name="url"],
.modal-newsletter input[name="url"] {
  display: none !important;
}

.newsletter {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  margin-top: 5px;
  font-size: 13px;
  position: relative;
  display: flex;
  align-items: center;
}

.newsletter input {
  width: 100%;
  font-size: 13px;
  line-height: 18px;
  background: #2d2e30;
  border: 1px solid transparent;
  border-right: 0 !important;
  outline: none !important;
  color: #ccc;
  height: 38px;
  padding: 10px 20px;
  box-shadow: none;
  border-radius: 3px 0 0 3px;
}

.newsletter .newsletter-button {
  height: 38px;
  padding: 6px 14px;
  border-radius: 0 3px 3px 0;
  line-height: 1.5;
  outline: none !important;
  font-size: 14px;
  border: 0;
  color: #fff;
  -moz-transition: 0.2s;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  white-space: nowrap;
}

.newsletter .newsletter-button:hover {
  opacity: 0.8;
}

.modal-newsletter .modal-dialog {
  max-width: 650px;
}

.modal-newsletter .modal-content {
  border-radius: 0;
}

.modal-newsletter .modal-body {
  padding: 50px;
}

.modal-newsletter .modal-body .close {
  display: inline-block;
  padding: 6px;
  color: #777;
  opacity: 1 !important;
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 11px;
  width: 34px;
  height: 34px;
}

.modal-newsletter .modal-title {
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 2px;
  margin: 0 0 15px;
}

.modal-newsletter .modal-desc {
  line-height: 26px;
  text-align: center;
  margin-bottom: 20px;
  color: #6c757d;
}

.modal-newsletter .form-input {
  background-color: #f8f8f8;
  height: 40px;
  line-height: 40px;
  border-radius: 0 !important;
}

.modal-newsletter .form-input:focus {
  border-color: #cfcfcf;
}

.modal-newsletter .btn {
  padding: 7px 30px !important;
  line-height: 26px;
  border-radius: 0 !important;
  color: #fff !important;
  border: 0 !important;
  white-space: nowrap;
}

.modal-newsletter .btn:hover {
  opacity: 0.9;
}

.modal-newsletter .modal-newsletter-inputs {
  position: relative;
  display: flex;
  align-items: center;
}

.modal-newsletter .text-close {
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
  margin: 0 !important;
  padding: 0 !important;
  text-decoration: underline;
  color: #6c757d;
}

.modal-newsletter .text-close:hover {
  color: #555;
}

.languages-dropdown {
  width: 100%;
  display: block;
  float: left;
  margin-top: 20px;
}

.languages-dropdown .btn {
  background-color: transparent !important;
  color: #aaa !important;
  font-size: 14px;
  padding: 0 !important;
}

.languages-dropdown .btn i {
  margin-right: 8px;
}

.languages-dropdown .dropdown-menu {
  background-color: #333 !important;
}

.languages-dropdown .dropdown-menu li a {
  padding: 6px 20px;
  color: #aaa !important;
}

.languages-dropdown .dropdown-menu li a:hover {
  background-color: #303030 !important;
}

.languages-dropdown .dropdown-menu li a:active {
  background-color: #303030 !important;
}

.languages-dropdown .dropdown-menu li a:focus {
  background-color: #303030 !important;
}

.footer-bottom {
  padding: 25px 0;
  font-size: 13px;
  border-top: 1px solid rgba(255, 255, 255, 0.06);
  line-height: 36px;
}

.footer-bottom p {
  font-size: 13px;
  line-height: 36px;
  margin: 0;
}

.scrollup {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 15px;
  color: #fff;
}

.scrollup i {
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: block;
  border-radius: 2px;
  text-align: center;
  background-color: #333;
}

/* ============ 10. Ad Spaces ============ */

/**/
.p-b-0 {
  padding-bottom: 0 !important;
}

.p-t-0 {
  padding-top: 0 !important;
}

.jconfirm.jconfirm-white .jconfirm-box .jconfirm-buttons button,
.jconfirm.jconfirm-light .jconfirm-box .jconfirm-buttons button {
  text-transform: none !important;
  font-weight: 500 !important;
}

.jconfirm .jconfirm-box div.jconfirm-title-c {
  text-align: center;
}

.animated {
  animation-fill-mode: none !important;
}

.text-style {
  line-height: 28px !important;
  font-size: 15px !important;
}

.footer-bottom-left {
  float: left;
}

.footer-bottom-right {
  float: right;
}

.nav-footer {
  margin: 0;
  padding: 0;
}

.nav-footer li {
  list-style: none;
  margin-right: 15px;
  display: inline-block;
}

.nav-footer li:last-child {
  margin-right: 0px;
}

.nav-footer li a {
  color: #9a9a9a;
  font-size: 13px;
  line-height: 16px;
}

.title-index {
  text-align: center;
  height: 0;
  margin: 0;
  padding: 0;
  line-height: 0;
  color: transparent;
  text-indent: -9999px;
}

.btn-go-home {
  margin-top: 30px;
}

.p-0 {
  padding: 0;
}

.m-0 {
  margin: 0;
}

.font-weight-normal {
  font-weight: normal !important;
}

.recaptcha-cnt {
  width: 100%;
  height: auto;
  display: block;
  text-align: center;
}

.page-login .g-recaptcha {
  width: 300px;
  height: 74px;
  margin: 0 auto;
}

.reactions {
  display: block;
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 45px;
}

.reactions .title-reactions {
  display: block;
  width: 100%;
  margin-bottom: 30px;
  text-transform: uppercase;
  font-size: 16px;
}

.reactions .col-reaction {
  width: 100px;
  cursor: pointer;
  margin-bottom: 5px;
  float: left;
}

.reactions .col-reaction:last-child {
  margin: 0;
}

.reactions .col-reaction .row {
  margin-bottom: 5px;
}

.reactions .col-reaction:hover .btn-reaction span {
  color: #fff;
}

.reactions .icon-cnt {
  display: block;
  width: 100%;
  height: 75px;
  float: left;
  position: relative;
  text-align: center;
}

.reactions .img-reaction {
  width: 55px;
  height: 55px;
}

.reaction-num-votes {
  border-radius: 15px;
  position: absolute;
  top: 8px;
  right: 15px;
  display: inline-block;
  min-width: 24px;
  opacity: 0.8;
  font-weight: 400;
}

.label-reaction {
  background-color: transparent;
  color: #222;
  font-size: 13px;
  font-weight: 400;
  display: inline-block;
  min-width: 30px;
  padding: 3px 6px;
  border-radius: 6px;
  cursor: pointer;
}

.label-reaction-voted {
  color: #fff;
}

.reaction-num-votes {
  background: #333;
}

.col-disable-voting {
  cursor: default !important;
}

.col-disable-voting .label-reaction {
  cursor: default;
}

.col-disable-voting .progress-bar-vertical .progress-bar {
  opacity: 0.7 !important;
}

.disable-voting {
  background-color: #e6e6e6 !important;
  cursor: default !important;
}

.disable-voting span {
  color: #222 !important;
}

.poll {
  display: block;
  float: left;
  width: 100%;
  position: relative;
  padding-bottom: 30px;
  margin-top: 30px;
  border-bottom: 1px solid #eee;
}

.poll:first-child {
  margin-top: 0 !important;
}

.poll:last-child {
  border: 0;
}

.poll .title {
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  text-transform: none;
  margin-top: 0;
}

.poll .option {
  margin-bottom: 15px;
}

.poll .button-cnt {
  margin-top: 30px;
}

.poll .button-cnt button {
  margin-right: 15px;
}

.poll .result {
  display: none;
}

.poll .result .progress {
  border-radius: 1px;
  background-color: transparent;
  box-shadow: none !important;
  background-color: #f3f3f3;
  color: #222;
  margin-bottom: 15px;
  height: 12px;
}

.poll .result .progress .progress-bar {
  font-size: 11px;
  font-weight: 600;
  background-color: #2cb29a;
  box-shadow: none !important;
}

.poll .result .progress .progress-bar-0 {
  color: #444;
}

.poll .result .progress .progress-bar-0 span {
  margin-left: 5px;
}

.poll-error-message {
  color: #e95350;
  font-size: 13px;
  display: none;
}

.a-view-results {
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
}

.total-vote {
  text-align: center;
  font-weight: bold;
  font-size: 13px;
  margin-top: 19px;
  margin-bottom: 5px;
}

.poll .result .progress span {
  position: absolute;
  right: 0;
  font-weight: 600;
  font-size: 11px;
  line-height: 11px;
}

.label-poll-option {
  font-weight: 400;
  cursor: pointer;
  display: initial !important;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.contact-map-container {
  display: block;
  float: left;
  width: 100%;
  margin-top: 30px;
}

.contact-map-container iframe {
  display: block;
  float: left;
  width: 100%;
  height: 460px;
}

.cookies-warning {
  background-color: #fafafc;
  border-radius: 2px;
  bottom: 18px;
  left: 20px;
  opacity: 0.9;
  position: fixed;
  width: 400px;
  max-width: 100%;
  z-index: 9999;
  border: 1px solid #e5e5e8;
}

.cookies-warning .text {
  font-size: 13px;
  padding: 24px 18px;
  color: #333;
  line-height: 24px;
}

.cookies-warning .icon-cl {
  cursor: pointer;
  padding: 8px;
  position: absolute;
  font-size: 12px;
  right: 1px;
  top: -5px;
  color: #7b7b7b !important;
}

.custom-checkbox {
  line-height: 17px;
  cursor: pointer;
  font-weight: 400 !important;
}

.custom-checkbox:after {
  content: "";
  display: table;
  clear: both;
}

.custom-checkbox .checkbox-icon {
  cursor: pointer;
  position: relative;
  display: inline-block;
  border: 1px solid #dee2e6;
  background-color: #dee2e6;
  border-radius: 0.15em;
  width: 1.3em;
  height: 1.3em;
  float: left;
  margin-right: 0.5em;
  -moz-transition: 0.2s;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.custom-radio .checkbox-icon {
  border: 1px solid #dee2e6;
  background-color: transparent;
}

.custom-checkbox .checkbox-icon i {
  position: absolute;
  font-size: 0.8em;
  line-height: 0;
  top: 3px;
  left: 3px;
}

.custom-checkbox input {
  display: none;
}

.custom-checkbox input + .checkbox-icon > i {
  opacity: 0;
}

.custom-checkbox input:checked + .checkbox-icon {
  background-color: #dee2e6;
  border: 1px solid #dee2e6;
}

.custom-checkbox input:checked + .checkbox-icon > i {
  opacity: 1;
  color: #fff;
}

.custom-checkbox input:disabled + .checkbox-icon {
  opacity: 0.5;
}

.swal-modal {
  max-width: 400px;
}

.swal-icon {
  width: 60px;
  height: 60px;
}

.swal-icon--warning__body {
  height: 28px;
}

.swal-button {
  border-radius: 3px !important;
  padding: 8px 22px !important;
}

.swal-button:focus {
  box-shadow: none !important;
}

.swal-text {
  font-size: 14px !important;
}

.is-invalid {
  border: 1px solid rgba(220, 53, 69, 0.4) !important;
  box-shadow: 0 0 0 0.05rem rgba(220, 53, 69, 0.4) !important;
}

.is-recaptcha-invalid > div {
  border: 1px solid rgba(220, 53, 69, 0.4) !important;
  box-shadow: 0 0 0 0.05rem rgba(220, 53, 69, 0.4) !important;
}

form label.error {
  color: #d43f3a;
  display: none !important;
}

form input.error {
  border: 1px solid rgba(220, 53, 69, 0.4) !important;
  box-shadow: 0 0 0 0.02rem rgba(220, 53, 69, 0.4) !important;
}

form textarea.error {
  border: 1px solid rgba(220, 53, 69, 0.4) !important;
  box-shadow: 0 0 0 0.02rem rgba(220, 53, 69, 0.4) !important;
}

form select.error {
  border: 1px solid rgba(220, 53, 69, 0.4) !important;
  box-shadow: 0 0 0 0.02rem rgba(220, 53, 69, 0.4) !important;
}

.alert-success h4 {
  font-size: 13px;
  margin: 0 !important;
}

.container-bn {
  width: 100%;
  display: block;
  float: left;
  overflow-x: hidden;
}

.container-bn .bn-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.container-bn .bn-content a {
  display: block;
}

.container-bn-article {
  float: none;
}

.container-bn-mb {
  display: none;
}

.widget-title .title,
.home-slider-item .title,
.home-slider-boxed-item .title,
.reactions .title-reactions,
.poll .title,
.w-popular-list li .title,
.random-post-slider .item-info .title,
.first-tmp-slider-item .item-info .title,
.post-item-horizontal .title,
.post-item .title,
.footer-widget .title,
.f-random-list li .title,
.post-content .post-title .title,
.related-posts .post-list li .title,
.related-posts .related-post-title .title,
.comment-tabs a,
.page-title,
.leave-reply-title,
.post-item-boxed .title,
.w-our-picks-list li .title,
.gallery-category-title {
  font-family: var(--inf-font-secondary);
}

.post-item-horizontal .item-image {
  float: left;
}

.post-item-horizontal .item-content {
  float: left;
}

.add-to-reading-list {
  padding: 0 !important;
}

a:hover,
a:focus,
a:active,
.text-category,
.navbar-inverse .navbar-nav > li > a:hover,
.navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus,
.navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover,
.read-more,
.post-content .post-meta a:hover,
.f-random-list li .title a:hover,
.random-post-slider .owl-prev:hover .random-arrow-prev,
.random-post-slider .owl-next:hover .random-arrow-next,
.post-detail-slider .owl-prev:hover .post-detail-arrow-prev,
.post-detail-slider .owl-next:hover .post-detail-arrow-next,
.link-forget:hover,
.nav-footer li a:hover,
.widget-list li .w-meta a:hover,
.post-content .post-text a,
.post-files .file button {
  color: var(--inf-main-color);
}

#infinity-outline {
  stroke: var(--inf-main-color);
}

.nav-mobile-header,
.navbar-toggle {
  background-color: var(--inf-main-color) !important;
}

.navbar-inverse .navbar-toggle {
  border-color: var(--inf-main-color) !important;
}

.btn-custom,
.btn-error-back,
.post-content .post-tags .tag-list li a:hover,
.custom-checkbox:checked + label:before {
  background-color: var(--inf-main-color);
  border-color: var(--inf-main-color);
}

::selection {
  background-color: var(--inf-main-color) !important;
  color: #fff;
}

::-moz-selection {
  background-color: var(--inf-main-color) !important;
  color: #fff;
}

.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:hover,
.navbar-inverse .navbar-nav > .active > a:focus,
.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .open > a:hover,
.navbar-inverse .navbar-nav > .open > a:focus,
.navbar-inverse .navbar-nav > li > a:focus,
.navbar-inverse .navbar-nav > li > a:hover {
  color: var(--inf-main-color) !important;
  background-color: transparent;
}

.home-slider-item .item-info .label-slider-category,
.label-post-category,
.widget-title .title::after,
.ramdom-post-slider .item-info .label-slider-category,
.w-tag-list li a:hover,
.related-posts .related-post-title .title::after,
.navbar-inverse .navbar-nav .active a::after,
.newsletter button,
.filters .active::after,
.filters .btn:focus:after,
.filters .btn:hover:after,
.filters .btn:active:after,
.label-slider-category,
.reactions .col-reaction:hover .btn-reaction,
.reactions .progress-bar-vertical .progress-bar,
.reactions .btn-reaction-voted,
.poll .result .progress .progress-bar,
.spinner > div,
.label-reaction-voted,
.switcher-box .open-switcher {
  background-color: var(--inf-main-color);
}

.pagination .active a {
  border: 1px solid var(--inf-main-color) !important;
  background-color: var(--inf-main-color) !important;
  color: #fff !important;
}

.leave-reply .form-control:focus,
.page-contact .form-control:focus,
.form-input:focus,
.custom-checkbox:hover + label:before {
  border-color: var(--inf-main-color);
}

.gallery-categories ul li a:hover,
.gallery-categories ul li a:focus,
.gallery-categories ul li a:active {
  background-color: var(--inf-main-color) !important;
  border-color: var(--inf-main-color) !important;
  color: #fff !important;
}

.newsletter .newsletter-button {
  background-color: var(--inf-main-color);
  border: 1px solid var(--inf-main-color);
}

.profile-buttons ul li a:hover {
  color: var(--inf-main-color);
  border-color: var(--inf-main-color);
}

.comment-section .nav-tabs .active {
  border-bottom: 2px solid var(--inf-main-color);
}

.cookies-warning a,
.post-meta .post-meta-inner a:hover {
  color: var(--inf-main-color) !important;
}

.custom-checkbox input:checked + .checkbox-icon {
  background-color: var(--inf-main-color);
  border: 1px solid var(--inf-main-color);
}

.swal-button--danger {
  background-color: var(--inf-main-color) !important;
}

/*
Responsive CSS Fixes
*/

@media (max-width: 1999.5px) {
  .home-slider-fixer .col-sl {
    width: 33.3333%;
  }

  .home-slider-fixer .col-sl-4 {
    display: none;
  }
}

/* screen size smaller  than 1200px */
@media (max-width: 1200px) {
  .container {
    width: 100%;
  }

  .post-item-horizontal .item-image {
    height: auto !important;
  }

  .w-our-picks-list li .post-image {
    height: auto !important;
  }

  .external-post-image {
    height: auto !important;
  }

  .related-posts .post-list li .img-responsive {
    height: auto !important;
  }

  .w-popular-list li .left {
    background-color: transparent !important;
  }

  .post-item .post-image {
    height: auto !important;
  }

  .post-item-boxed .item-image {
    height: auto !important;
  }

  .profile-page .post-item-boxed .item-image {
    height: auto !important;
  }
}

@media (max-width: 1199.5px) {
  .home-slider-fixer .col-sl {
    width: 50%;
  }

  .home-slider-fixer .col-sl-3 {
    display: none;
  }
}

/* screen size smaller  than 1200px and bigger than 992px */
@media (max-width: 1200px) and (min-width: 992px) {
  .popular-comment-span {
    display: none;
  }

  .post-item-horizontal .post-buttons {
    bottom: 0;
    display: block;
    float: left;
    padding-right: 15px;
    position: relative;
    right: 0;
    width: 100%;
  }
}

/* screen size smaller  than 1200px and bigger than 768px */
@media (max-width: 1200px) and (min-width: 768px) {
  .navbar .container {
    width: 100%;
  }

  .social-nav {
    padding: 0;
  }

  .navbar-left {
    padding-right: 0;
  }
}

/* screen size smaller  than 1100px and bigger than 768px */
@media (max-width: 1100px) and (min-width: 768px) {
  .navbar-nav > li {
    margin-left: 0px !important;
  }

  .navbar-nav > li {
    margin-right: 0px !important;
  }

  .navbar-inverse .navbar-nav > li > a {
    font-size: 12px;
  }

  .dropdown-more .more-sign {
    font-size: 17px;
  }

  .social-nav .navbar-nav > li > a {
    font-size: 11px !important;
  }
}

@media (max-width: 767.5px) {
  .home-slider-fixer .col-sl {
    width: 100%;
  }

  .home-slider-fixer .col-sl-2 {
    display: none;
  }

  .modal-newsletter .modal-body {
    padding: 30px;
  }

  .modal-newsletter .modal-newsletter-inputs {
    display: block;
  }

  .modal-newsletter .form-input {
    margin-bottom: 10px;
  }

  .modal-newsletter .btn {
    width: 100% !important;
  }
}

@media (max-width: 991px) {
  #header {
    height: auto;
    box-shadow: none !important;
    border: 0 !important;
  }

  .navbar-inverse {
    height: auto;
  }

  .nav-top-container {
    display: none;
  }

  .nav-desktop {
    display: none;
  }

  .navbar-header {
    display: none;
  }

  .nav-mobile-header {
    display: block;
  }

  .mobile-nav-container {
    min-height: 60px;
  }

  .navbar-collapse {
    width: 100% !important;
    overflow-x: hidden !important;
  }

  .navbar-collapse.collapse {
    display: none !important;
  }

  .navbar-collapse.collapse.in {
    display: block !important;
  }

  .navbar-inverse .navbar-nav > li > a {
    padding: 0 !important;
  }

  .navbar-header .collapse,
  .navbar-toggle {
    display: block !important;
  }

  .navbar-header {
    float: none;
  }

  .profile-dropdown {
    margin-top: 10px !important;
  }

  .search-icon {
    margin: 20px 0 0 0 !important;
    cursor: pointer;
    display: inline-block;
    font-size: 30px;
    position: relative;
    float: right;
  }

  .search-icon i {
    font-size: 19px !important;
  }

  .modal-search {
    background: #fff none repeat scroll 0 0;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
    display: none;
    position: fixed;
    right: 0 !important;
    top: 60px;
    transition: all 0.2s ease-in-out 0s;
    width: 100%;
    z-index: 999999;
    background-color: #00a65a;
  }

  .modal-search .form-control:focus,
  .modal-search .form-control {
    font-size: 13px;
    height: 54px;
    padding: 15px;
    position: absolute;
    width: 100%;
    margin-top: 0 !important;
  }

  .modal-search .s-close {
    box-shadow: none;
    color: #999;
    cursor: pointer;
    font-size: 12px;
    position: absolute;
    right: 5px;
    top: 4px;
    padding: 10px;
  }

  /*navbar*/
  .navbar-inverse {
    padding-top: 0;
  }

  .navbar-header {
    border-bottom: 1px solid #eee;
  }

  .navbar-toggle {
    margin-right: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .navbar-inverse .navbar-nav {
    margin-bottom: 0 !important;
  }

  .navbar-collapse .navbar-nav {
    padding-top: 10px;
  }

  .navbar-inverse .dropdown .dropdown-menu > li > a {
    line-height: 24px !important;
  }

  .navbar-inverse .navbar-nav .dropdown-menu {
    width: 100% !important;
    border: 0 none;
    border-radius: 0;
    left: 0;
    margin-top: 0;
    padding: 0;
    box-shadow: none;
    position: relative !important;
    margin-bottom: 15px;
  }

  .navbar > .container .navbar-brand,
  .navbar > .container-fluid .navbar-brand {
    margin-left: 15px !important;
  }

  .navbar-brand img {
    max-width: 150px;
  }

  .profile-drop {
    display: inline-block !important;
    width: auto !important;
  }

  .profile-dropdown {
    margin-top: 5px;
  }

  .social-nav .navbar-nav {
    margin-top: 0 !important;
  }

  .navbar-brand {
    background-color: transparent !important;
    display: block;
    margin-left: 15px;
    margin-top: 8px;
    margin-right: 5px;
    margin-top: 10px;
    padding: 0 !important;
  }

  .navbar-brand img {
    max-width: 150px;
    max-height: 45px;
    display: block;
  }

  .navbar-nav .dropdown .top-dropdown {
    width: 96% !important;
  }

  .page-login {
    padding: 30px 0;
  }

  .container-bn-ds {
    display: none;
  }

  .container-bn-mb {
    display: block;
  }
}

/* screen size smaller than 768px */
@media (max-width: 768px) {
  .cookies-warning {
    position: fixed;
    left: 15px;
    width: 80%;
  }
}

/* screen size smaller than 767px */
@media (max-width: 767px) {
  .img-popular {
    display: none;
  }

  .img-popular-mobile {
    display: block;
  }

  .hidden-sm {
    display: none;
  }

  .mobile-li {
    display: block !important;
  }

  /*slider*/
  .home-slider-item .item-info {
    padding: 10px;
  }

  .home-slider-item .item-info .title {
    display: block;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    margin: 5px 0;
  }

  /*Post List*/
  #main {
    margin-top: 25px;
  }

  .post-item .post-footer .title {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    margin: 10px 0;
  }

  .post-item-boxed .title {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    margin: 10px 0;
  }

  .post-item-horizontal .title {
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    margin: 10px 0;
  }

  .post-item .post-footer .summary {
    color: #888;
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
  }

  .post-item-boxed .summary {
    color: #888;
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
  }

  .post-item-horizontal .summary {
    color: #888;
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
  }

  .read-more {
    border-radius: 2px;
    font-size: 13px;
    font-weight: 400;
    padding: 3px 6px;
  }

  .read-more:hover .read-more-i {
    margin-left: 25px;
    margin-right: 5px;
  }

  .post-item .post-footer .post-buttons .post-social li {
    margin-right: 15px;
  }

  .post-item .post-footer .post-meta span {
    font-size: 10px;
  }

  .post-item .post-footer .post-buttons .post-social {
    padding: 2px 6px 3px 0;
  }

  .post-item {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  .post-item-horizontal {
    margin-bottom: 15px;
  }

  .post-item-horizontal .item-image {
    width: 100%;
    display: block;
    float: left;
  }

  .post-item-horizontal .item-image img {
    width: 100%;
  }

  .post-item-horizontal .item-content {
    width: 100%;
    display: block;
    float: left;
    padding-left: 0;
    margin-top: 15px;
  }

  .post-item-horizontal .post-buttons {
    position: relative;
    padding: 0;
  }

  .post-content .post-summary h2 {
    font-size: 15px;
  }

  .post-meta .post-meta-inner span,
  .post-meta .post-meta-inner a {
    font-size: 12px;
  }

  /*Sidebar*/
  .widget-popular-posts {
    margin-top: 30px;
  }

  .ramdom-post-slider .item-info {
    padding: 15px;
  }

  .ramdom-post-slider .item-info .title {
    color: #fff;
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
    margin-top: 5px;
  }

  .about-author-left {
    display: block;
    text-align: center;
  }

  .about-author-right {
    display: block;
    margin-top: 15px;
  }

  /*footer*/
  #footer {
    background-color: #222;
    color: #ccc;
    margin-top: 30px;
    padding-top: 30px;
  }

  .newsletter input {
    width: 100%;
    margin-right: 0;
  }

  #ad-space {
    padding: 15px 0;
  }

  .page-breadcrumb {
    display: none;
  }

  .post-content .post-title .title {
    margin-top: 0 !important;
  }

  .page-about {
    font-size: 13px;
    line-height: 24px;
  }

  .page-title {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .post-content .post-title .title {
    font-size: 26px;
    font-weight: 400;
    line-height: 34px;
    margin-top: 10px;
  }

  .post-content .post-meta span {
    font-size: 10px;
  }

  .post-content .post-text {
    font-size: 13px;
    line-height: 24px;
  }

  .post-content .post-tags .tag-list li a {
    font-size: 11px;
    padding: 2px 5px;
  }

  .post-content .post-share .btn-share {
    height: 40px;
    line-height: 40px;
    margin-right: 8px;
    width: 40px;
  }

  .post-content .post-share .btn-share i {
    margin-right: 0px;
  }

  .related-posts .post-list li {
    margin-bottom: 15px;
  }

  .related-posts .post-list li .title {
    margin-top: 10px;
  }

  .add-to-reading-list {
    float: left !important;
    margin-top: 0px;
  }

  .delete-from-reading-list {
    float: left !important;
    margin-top: 10px;
  }

  .footer-bottom-left {
    display: block;
    text-align: center;
    width: 100%;
  }

  .footer-bottom-left p {
    display: block;
    text-align: center;
    width: 100%;
  }

  .footer-bottom-right {
    display: block;
    text-align: center;
    width: 100%;
  }

  .nav-footer {
    display: block;
    text-align: center;
    width: 100%;
  }

  .footer-bottom {
    padding-bottom: 60px;
  }

  .section-comments .nav > li > a {
    padding: 10px 3px !important;
    font-size: 13px;
  }

  .post-content .post-meta form {
    float: left !important;
    margin-top: 0px;
    display: block;
    width: 100%;
    text-align: left;
  }

  .post-content .post-meta form button {
    float: left !important;
    margin: 0 !important;
  }

  .posts {
    width: 100% !important;
  }

  .btn-optional-link {
    padding: 8px 20px;
    font-size: 13px;
    border-radius: 2px;
  }

  .rss-item .left {
    display: block;
    width: 100%;
  }

  .rss-item .right {
    display: block;
    width: 100%;
  }

  .rss-item .right p {
    word-break: break-all;
  }

  .item-boxed-cnt {
    padding: 0;
  }

  .posts-boxed {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .recaptcha-cnt .g-recaptcha {
    -webkit-transform: scale(0.82);
    -moz-transform: scale(0.82);
    -ms-transform: scale(0.82);
    -o-transform: scale(0.82);
    transform: scale(0.82);
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    transform-origin: 0 0;
  }

  .profile-tabs {
    margin-bottom: 30px;
  }

  .profile-details {
    display: block !important;
    width: 100%;
  }

  .profile-details .left {
    display: block !important;
    text-align: left;
    width: 100%;
    padding: 0;
    margin-bottom: 15px;
  }

  .profile-details .right {
    display: block !important;
    width: 100%;
  }

  .widget-popular-posts {
    margin-top: 50px;
  }

  .comment-section .nav-tabs li a {
    padding: 10px 20px;
    font-size: 13px;
  }
}

/* screen size bigger than 480px */
@media (max-width: 480px) {
  .post-item-boxed .item-content {
    padding: 10px;
  }

  .w-popular-list li img {
    float: left;
    height: auto;
    margin-right: 15px;
    position: relative;
    width: 100%;
  }

  .page-content iframe {
    height: auto !important;
  }

  .post-text iframe {
    height: auto !important;
  }

  .navbar-brand {
    margin-bottom: 0 !important;
  }

  .w-popular-list li img {
    float: none;
    margin-bottom: 5px;
  }

  .post-avatar {
    margin-right: 5px;
  }

  .post-content .post-meta span {
    float: left;
  }

  .post-content .post-meta a {
    float: left;
  }

  .add-to-reading-list {
    float: right;
  }

  .home-slider-boxed-item .item-info {
    padding: 20px;
  }

  .home-slider-boxed-item .item-info .title {
    line-height: 24px;
    font-size: 18px;
  }
}

@media (max-width: 391px) {
  .home-slider-item .item-info .title {
    font-size: 18px;
  }

  .home-slider-boxed-item .item-info {
    padding: 10px;
  }
}


/* Post Image */
.post-image {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: auto;
}

.post-image img {
  display: block;
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .post-image img {
    max-width: 100%;
    height: auto;
  }
}


