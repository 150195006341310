@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;500;600;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Poppins:wght@100;200;300;500;600;900&display=swap");

.App {
  /* text-align: center; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  background: #ffffff;
}
body {
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* scroll bar */

::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(214, 214, 214, 0.57);
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b78400;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1c3d47;
}

/* prayer wall */

.prayerWallProfile {
  position: relative;
  z-index: 1;
  left: 1vw;
}

.prayerWallCard {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0% 5%;
  margin-top: -3%;
  margin-bottom: 5%;
  margin-left: 5%;
}

.prayerWallAuthorDate {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: inherit;
}

.prayerWallAuthor {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: #1c3d47;
}

.prayerWallDate {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 9px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: #b78400;
  margin-left: 1%;
}

.prayerWallText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;
  color: #868686;
  text-align: left;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 599px) {
  .prayerWallProfile {
    position: relative;
    z-index: 1;
    left: 0;
  }

  .prayerWallCard {
    width: 90%;
    /* height: 35%; */
    padding: 0% 6%;
    margin-top: -8%;
    margin-bottom: 5%;
    margin-left: 6%;
  }

  .prayerWallAuthor {
    margin-left: 2vw;
  }

  .prayerWallAuthorDate {
    margin-top: 3%;
    margin-bottom: -2%;
  }

  .prayerWallDate {
    margin-left: 2%;
  }
}

/* Small devices*/
@media only screen and (min-width: 600px) and (max-width: 767px) {
  .prayerWallProfile {
    position: relative;
    z-index: 1;
    margin-right: 85%;
  }

  .prayerWallCard {
    width: 90%;
    /* height: 24%; */
    padding: 0% 6%;
    margin-top: -5%;
    margin-bottom: 5%;
    margin-left: 6%;
  }

  .prayerWallAuthorDate {
    margin-top: 3%;
    margin-bottom: -2%;
  }

  .prayerWallDate {
    margin-left: 2%;
  }
}

/* Medium devices */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* all fine */
}

/* Large devices  */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .prayerWallProfile {
    position: relative;
    z-index: 1;
    margin-right: 85%;
  }

  .prayerWallCard {
    width: 90%;
    /* height: 24%; */
    padding: 0% 6%;
    margin-top: -5%;
    margin-bottom: 5%;
    margin-left: 6%;
  }

  .prayerWallAuthorDate {
    margin-top: 3%;
    margin-bottom: -2%;
  }

  .prayerWallDate {
    margin-left: 2%;
  }
}

/* xl devices  */
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  /* all fine */
}
