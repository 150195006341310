.intro {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6% 13%;
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
}

.description_img {
  height: 10vh
}

/* title */

.title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.title p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 16px;
  color: #1c3d47;
  margin-right: 2%;
}

.title img {
  margin-bottom: 2%;
  width: 54px;
  height: 1px;
}

/* author */

.author {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #767676;
}

/* description */

.description {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 2%;
}

.description img {
  margin-right: 3%;
}

.description p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
  color: #6c6c6c;
  text-align: left;
}

/* prayer stories */

.prayer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.prayerTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
  margin-bottom: 3%;
}

.prayerTitle img {
  width: 54px;
  height: 1px;
}

.prayerTitle p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #1c3d47;
  margin: 0% 10%;
}

.stories {
  height: 570px;
  width: 50%;
  overflow-y: scroll;
}

.card {
  background: rgba(243, 243, 243, 0.54);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 45%;
  height: 77px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0% 2%;
  margin-top: 1%;
  margin-left: 1vw;
  padding-top: 20px
}

.prayer_textarea {
  background-color: transparent;

}

.card div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.post {
  background: #1c3d47;
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.161));
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  width: 58px;
  height: 34px;
  position:relative;
  left:1vw
}

.profile {
  position: relative;
  z-index: 1;
  margin-right: 45%;
  margin-top: 1%;
  top: 4vh;
}

/* sign in */

.signin {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 45%;
  margin-top: 1.5%;
}

.signin p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  color: #1c3d47;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 599px) {
  .intro {
    display: flex;
    flex-direction: column;
    padding: 10% 2%;
  }

  .description_img {
    margin-top: 2%;
    height: 20vh;
    width: 3px;
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 80%;
    margin-bottom: 5%;
  }

  .prayerImage {
    width: 80%;
  }

  .stories {
    width: 80%;
  }

  .profile {
    position: relative;
    z-index: 1;
    margin-right: 66%;
    margin-top: 5%;
    top: 0vh;
  }

  .card {
    width: 72%;
    height: 80px;
    margin-top: -3%;
    padding: 0% 5%;
  }

  .signin {
    width: 72%;
  }
}

/* Small devices*/
@media only screen and (min-width: 600px) and (max-width: 767px) {
  .intro {
    display: flex;
    flex-direction: column;
    padding: 6% 0%;
  }

  .stories {
    width: 80%;
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 90%;
    margin-bottom: 5%;
  }

  .profile {
    position: relative;
    z-index: 1;
    margin-right: 69%;
    margin-top: 1%;
    top: 2vh
  }

  .card {
    width: 72%;
    margin-left:3vw;
  }

  .signin {
    width: 72%;
  }
}

/* Medium devices */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .intro {
    display: flex;
    flex-direction: column;
    padding: 6% 0%;
  }

  .stories {
    width: 80%;
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 90%;
    margin-bottom: 5%;
  }

  .profile {
    position: relative;
    z-index: 1;
    margin-right: 72%;
    margin-top: 1%;
    top: 2vh;
    left: 1vw;
  }

  .card {
    width: 72%;
    margin-left: 2vw;
  }

  .signin {
    width: 72%;
  }
}

/* Large devices  */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .intro {
    padding: 6% 5%;
  }

  .prayerImage {
    width: 45%;
  }

  .description img {
    margin-top: 2%;
    height: 100px;
    width: 3px;
  }

  .card {
    margin-left: 2vw;
  }
}

/* xl devices  */
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .intro {
    padding: 6% 10%;
  }

  .description img {
    margin-top: 2%;
  }
}
