/* xxl devices */

.navbar {
  border-bottom: outset 1px white;
  background-color: #293b56;
  width: 100vw;
  position: absolute;
  top: 0px;
}

.navbar__navLink_active {
  border-bottom: 5px #ac7c00 solid;
  border-radius: 4px;
  padding-bottom: 10px;
}

.nav__collapse {
  justify-content: flex-end;
}

.nav {
  justify-content: space-between;
  min-width: 70%;
}

.navbar__navLink__link {
  text-decoration: none;
}

.navbar__navLink {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: white;
  text-align: center;
  padding: 10px 10px;
}

.navbar__navLink__link {
  align-items: center;
  margin: auto;
  text-decoration: none;
}

.navbar__navLink__link:hover {
  border-bottom: 5px #ac7c00 solid;
  border-radius: 4px;
  padding-bottom: 10px;
}

.navbar__navLink__link:hover::after {
  border-bottom: 5px black solid;
  padding-bottom: 10px;
  transform: scaleY(1);
}

.active__navLink__Home {
  border-bottom: 10px solid black;
  position: absolute;
  top: 75px;
  width: 4%;
}

#navbarScrollingDropdown {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: white;
  align-items: center;
  margin: auto;
}

.navbar__navLink__signUp {
  margin-left: 15px;
  border: 1px rgba(255, 255, 255, 0.12) solid;
  padding: 9px 16px;
  border-radius: 4px;
}

/* Large devices  */
@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .navbar__navLink {
    align-items: center;
  }

  .navbar__navLink__signUp {
    white-space: nowrap;
  }

  .nav__collapse {
    justify-content: flex-end;
  }
}

/*
.navbar .navLink {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: white;
  margin-right: 50px;
} */

/* .flags {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

/* .navbar #signup {
  background: rgba(255, 255, 255, 0.12);
  border-radius: 4px;
  width: 79px;
  height: 38px;
} */
/* 
.navbar .searchButton {
  display: flex;
  margin-right: 10px;
} */

/* .navbar .translate {
  margin-left: 50px;
} */

/* xl devices  */
/* @media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .navbar #navbarScroll {
    margin-right: 0%;
  }

  .navbar .navLink {
    margin-right: 20px;
  }

  .navbar .searchButton {
    margin-right: 20px;
  }

  .navbar .translate {
    margin-left: 40px;
  }
} */

/* Large devices  */
/* @media only screen and (min-width: 992px) and (max-width: 1200px) {
  .navbar #navbarScroll {
    margin-right: 0%;
  }

  .navbar .navLink {
    margin-right: 20px;
  }

  .navbar .searchButton {
    margin-right: 20px;
  }

  .navbar .translate {
    margin-left: 40px;
  }
} */

/* Medium devices */
/* @media only screen and (min-width: 768px) and (max-width: 992px) {
  .navbar #navbarScroll {
    margin-right: 0%;
  }

  .navbar .navLink {
    margin-right: 0px;
  }

  .press {
    margin-right: -2%;
  }

  .navbar .searchButton {
    margin-right: 0px;
  }

  .navbar .translate {
    margin-left: 0px;
  }
} */

/* Small devices*/
/* @media only screen and (min-width: 600px) and (max-width: 768px) {
  .navbar #navbarScroll {
    margin-right: 0%;
  }

  .navbar .navLink {
    margin-right: 0px;
  }

  .press {
    margin-right: -2%;
  }

  .navbar .searchButton {
    margin-right: 0px;
  }

  .navbar .translate {
    margin-left: 0px;
  }
} */

/* Extra small devices (phones, 600px and down) */
/* @media only screen and (max-width: 600px) { */
/* switch to mobile navigation bar component */
/* } */
