#form_Body_01 {
  display: flex;
  justify-content: center;
}

#form_body_02 {
  display: flex;
  justify-content: center;
  margin-top: 3vh;
  margin-bottom: 3vh;
  height: 10vh;
}

.Security_body {
  margin-top: 7vh
}

.form_number_02 {
  display: flex;
  justify-content: center;
}

.form_text {
  position:relative;
  top:10px;
  left:40px
}

.form_number_container {
  margin-top: 0;
  /* margin-bottom: 1rem; */
  background-color: #B78400;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 6px solid #E1E1E1;
  color: white;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  text-transform: uppercase;
  color: #FFFFFF;
  /* position: relative; */
  padding: 5px;
}

.form_border {
  background-color: #C0C0C0;
  width: 2px;
  height: 15vh;
  margin: 0 16px;
}

#form_border_02 {
  background-color: #C0C0C0;
  width: 2px;
  height: 5vh;
  margin: 0 16px;
}

.form_container {
  display: flex;
  justify-content: space-between;
  width: 50vw;
  background-color: #F8F8F8;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  margin-left: 20px;
}

.form_container_02 {
  display: flex;
  justify-content: space-between;
  width: 50vw;
  background-color: #F8F8F8;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  margin-left: 20px;
}

.form_body_container {
  width: 100%;
  margin: 2vh 1vw;
  display: flex;
  justify-content: space-between;
}

.form_body_container_section_2 {
  display: flex;
  justify-content: space-between;
  width: 100%
}

.form_body_container_section_form {
  margin: 2vh 1vw;
}

.form_body_label {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #3D2C00;
}

.form_input {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #555555;
  width: 150px;
  border: none;
  border-bottom: #555555 1px solid;
}

.form_output {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #555555;
  width: 150px;
  border: none;
  background-color: transparent;
  -webkit-appearance: none;
}

.form_body_button {
  background: #9A6F00;
  width: 91px;
  height: 41px;
  border: none;
  border-radius: 5px;
  color: white;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  margin: 1vh 1vw;
}

.saveModal_button {
  background: #9A6F00;
  width: 81px;
  height: 41px;
  border: none;
  border-radius: 5px;
  color: white;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
}

.form_body_button_container {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) {
  .form_container {
    width: 70vw;
  }

  .Security_body {
    margin-top: 7vh
  }

  .form_container_02 {
    width: 70vw;
  }

  .form_body_container {
    width: 100%;
  }
}

@media only screen and (max-width: 1199px) and (min-width: 900px) {
  .form_container {
    width: 80vw;
  }

  .Security_body {
    margin-top: 9vh
  }

  .form_container_02 {
    width: 80vw;
  }

  .form_border {
    /* height: 30vh; */
  }

  .form_body_container {
    width: 100%;
    margin: 3vh 0vw;
  }

  .form_body_container_section {

  }

  .form_body_container_section_form {
    margin-top: 20px
  }

  .form_body_button {
    margin: 10px;
  }
}

@media only screen and (min-width:600px) and (max-width: 899px) {
  .form_container {
    display: block;
    width: 70vw;
  }

  .form_container_02 {
    display: block;
    width: 70vw;
  }

  .Security_body {
    margin-top: 7vh
  }

  .form_border {
    background-color: #C0C0C0;
    width: 2px;
    height: 29vh;
    margin: 0 16px;
  }

  #form_border_02 {
    background-color: #C0C0C0;
    width: 2px;
    height: 9vh;
    margin: 0 16px;
  }

  .form_body_container {
    margin: 0px;
    display:block;
    padding: 5px 0 0 10px;
  }

  .form_body_container_section_2 {
    display:block;
  }

  .form_body_container_section {

  }

  .form_body_container_section_form {
    margin: 20px 10px;
  }

  #form_body_02 {
    display: flex;
    justify-content: center;
    margin-top: 3vh;
    height: 15vh;
  }
}

@media only screen and (max-width: 599px) {
  .form_container {
    width: 60vw;
    display: block;
    margin-left: 7px;
  }

  .Security_body {
    margin-top: 4vh
  }

  .form_body_container {
    margin: 1vh 3vw;
    display: block;
  }

  .form_border {
    height: 40vh;
  }

  #form_border_02 {
    height: 13vh;
  }

  .form_container_02 {
    width: 60vw;
    display: block;
    margin-left: 7px
  }

  .form_body_container_section_2 {
    display:block;
    padding:1px;
  }

  .form_body_container_section_form {
    margin: 15px 10px;
    display: flex;
    flex-direction: column;
  }

  .form_body_label {
    font-size: 11px;
  }

  .form_input {
    font-size: 12px;
    width: 140px;
  }

  .form_output {
    font-size: 11px;
    width: 140px;
    -webkit-appearance: none;
  }

  .form_body_button {
    width:81px;
    height: 36px;
    font-size: 11px;
  }

  #form_body_02 {
    height: auto;
  }

}