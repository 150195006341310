/* xxl devices */

.header {
  background-color: #293b56;
  background-image: url("https://storage.googleapis.com/public_ocm/Cover%20home%20page%20onorio%20(2).png");
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  font-size: calc(10px + 2vmin);
  color: white;
}

.mobileMenu {
  display: none;
}

.onorio {
  display: none;
}

.header .title {
  display: flex;
  align-items: flex-end;
}

.header .description {
  width: 95%;
  margin-bottom: 2%;
  padding-left: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.header h1 {
  font-family: "Publico Text";
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 70px;
}

.header h2 {
  font-family: "Publico Text";
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 60px;
}

.header p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 30px;
}

.header .left {
  text-align: left;
}

.header .book {
  background: rgba(255, 255, 255, 0.26);
  border-radius: 100px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #b78400;
  padding: 13px 17px 13px 20px;
  border-width: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 135px;
}

.header .right {
  display: flex;
  width: 40%;
  justify-content: space-evenly;
}

.header .joinUs {
  background: #b78400;
  border-radius: 4px;
  padding: 9px 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  border-width: 0px;
  color: white;
  width: 80px;
  height: 38px;
}

.header .list {
  display: flex;
  justify-content: space-between;
  width: 50%;
  align-items: center;
  margin-bottom: 3%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 599px) {
  .header {
    background-image: none;
    min-height: 40vh !important;
  }

  .mobileMenu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .desktopMenu {
    display: none;
  }

  .header .mobileMenu {
    width: 135px;
    height: 40px;
  }

  .onorio {
    display: unset;
    width: 100%;
    margin-top: 15%;
  }

  .header .description {
    width: 100%;
    margin-bottom: 0%;
    padding-left: 5%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: -5%;
  }

  .header .title {
    display: flex;
    align-items: center;
  }

  .header h1 {
    font-size: 28px;
  }

  .header h2 {
    font-size: 21px;
  }

  .header p {
    font-size: 10px;
    margin-right: 5%;
    text-align: left;
  }

  .header .book {
    width: 132px;
    height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10%;
  }

  .header .right {
    width: 95%;
    justify-content: space-between;
  }

  .header .joinUs {
    width: 100px;
    height: 38px;
    margin-top: 2%;
  }

  .header .list {
    display: flex;
    justify-content: space-between;
    width: 95%;
    align-items: center;
    margin-bottom: 3%;
  }

  .list .verticelLine {
    height: 60px;
  }

  .list .icon {
    height: 51.5px;
  }
}

/* Small devices*/
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .header {
    min-height: 59vh;
  }

  .desktopMenu {
    display: none;
  }

  .mobileMenu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .header .mobileMenu {
    width: 135px;
    height: 40px;
  }

  .header .mobileMenu .onorioLogo {
    width: 170px;
    height: 50px;
  }

  .header .description {
    width: 100%;
    margin-bottom: 2%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .header .description .right {
    width: 100%;
  }

  .header .list {
    width: 90%;
  }
}

/* Large devices  */
@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .header {
    min-height: 55vh;
  }

  .mobileMenu {
    display: none;
  }

  .desktopMenu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .header .desktopMenu .onorioLogo {
    width: 170px;
    height: 50px;
  }

  .header .description {
    width: 100%;
    margin-bottom: 8%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: 4%;
  }

  .header .description .right {
    width: 100%;
  }

  .header .description .right p {
    margin-right: -10%;
  }

  .header .list {
    width: 90%;
  }

  .header h1 {
    font-size: 40px;
  }

  .header h2 {
    font-size: 30px;
  }

  .header .right {
    margin-top: 3%;
  }

  .header .joinUs {
    width: 85px;
    height: 35px;
  }

  .header .list {
    margin-bottom: 4%;
  }
}

/* xl devices  */
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .mobileMenu {
    display: none;
  }

  .desktopMenu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .header {
    height: 780px;
  }

  .header .description {
    padding-left: 4%;
  }

  .header .right {
    width: 50%;
  }

  .header .right p {
    font-size: 12px;
  }

  .header .list {
    margin-bottom: 5%;
  }
}