.OfferingHeader {
  width: 100vw;
  height: 50vh;
}

.OfferingBody {
  display: flex;
  justify-content: center;
  align-items: center;
}

.OfferingDescription {
  width: 60%;
  position: relative;
}

.OfferingDescription__container {
  display: flex;
}

.OfferingDescription_text_container {
  text-align: center;
}

.recentOffering {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  text-transform: uppercase;
  color: #FFFFFF;
}

.OfferingText {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
}

.OfferingBanner {
  width: 70vw;
  height: 10vh;
  margin-bottom: -5vh;
  background-color: #1C3D47;
  position: relative;
  display: flex;
  align-items: center;
  align-self: flex-end;
}

.OfferingBannerText {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 36px;
  color: #F9F9F9;
  position: relative;
  left: 3vh;
  margin: 0px;
  text-align: center;
}

@media only screen and (max-width: 1200px) and (min-width: 900px) {

  .recentOffering {
    font-size: 32px;
    line-height: 50px;
  }

  .OfferingText {
    font-size: 13px;
    line-height: 20px;
  }
}

@media only screen and (max-width: 899px) and (min-width: 600px) {

  .OfferingDescription {
    width: 90%;
    position: relative;
  }
  .recentOffering {
    font-size: 25px;
    line-height: 40px;
  }

  .OfferingText {
    font-size: 12px;
    line-height: 18px;
}
}

@media only screen and (max-width: 599px) and (min-width:401px) {
  .OfferingDescription {
    width: 90%;
    position: relative;
  }

  .recentOffering {
    font-size: 30px;
    line-height: 50px;
  }

  .OfferingText {
    font-size: 12px;
    line-height: 18px;
}
  .OfferingBannerText {
    font-size: 20px;
    line-height: 20px;
  }

}

@media only screen and (max-width: 400px) {
  .OfferingDescription {
    width: 90%;
    position: relative;
  }

  .recentOffering {
    font-size: 20px;
    line-height: 20px;
  }

  .OfferingText {
    font-size: 10px;
    line-height: 17px;
  }

  .OfferingBannerText {
    font-size: 20px;
    line-height: 20px;
    text-align: center;
  }

}