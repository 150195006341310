/* navigation bar */

.mobileMenu {
  display: none;
}

/* body */

.body {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: -10%;
  margin-left: -12%;
}

.startMiddle {
  display: flex;
}

/* start */

.start {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 3.5%;
}

.startLine {
  margin-top: 40%;
}

/* middle */

.middle {
  margin-left: 1%;
}

.middle p {
  text-align: left;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;
  color: #1c3d47;
  margin-top: 4%;
}

/* end */

.end {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1.5%;
  margin-top: 2.2%;
  width: 20.5%;
}

/* title */

.endTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #1c3d47;
}

/* date and time */

.dateTime {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.dateTime p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: #979797;
  margin-left: 2%;
}

.dateTime > p {
  margin-right: 5%;
}

/* text */

.endText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #646464;
  width: 394px;
  text-align: left;
}

/* input */

.endInput {
  display: flex;
  text-align: left;
}

.endInput p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #1c3d47;
  margin-bottom: 2%;
}

.endInput input {
  padding: 0% 0% 0% 6%;
}

.number {
  margin-right: 8%;
}

.number input {
  margin-right: 6%;
  border: 0.5px solid #cfcfcf;
  width: 221.7px;
  height: 43.31px;
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #666666;
}

.price input {
  background: #f7f7f7;
  border: 0.5px solid #cfcfcf;
  border-radius: 5px;
  width: 186.74px;
  height: 43.31px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #6f6f6f;
}

.endLink {
  align-self: flex-end;
  margin-top: 7%;
}

.end Button {
  background: #1c3d47;
  width: 79px;
  height: 40.43px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  vertical-align: middle;

}

.end Button p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
}

@media only screen and (max-width: 599px) {
  .mobileMenu {
    display: unset;
    margin-bottom: -10%;
    margin-top: 1%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0% 5%;
  }

  .mobileMenu .onorioLogo {
    width: 94px;
    height: 25px;
  }

  .desktopMenu {
    display: none;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 599px) {
  .mobileMenu {
    display: unset;
    margin-bottom: -10%;
    margin-top: 2%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0% 5%;
  }

  .mobileMenu .onorioLogo {
    width: 135px;
    height: 40px;
  }

  .desktopMenu {
    display: none;
  }

  /* body */

  .body {
    flex-direction: column;
    align-items: center;
    margin-top: 10%;
    margin-left: 0%;
  }

  .startMiddle {
    margin-bottom: 5%;
  }

  /* start */

  .start {
    margin-left: -10%;
  }

  /* end */

  .end {
    align-self: flex-start;
    margin-left: 3%;
    /* width: 10%; */
  }

  .endTitle {
    width: max-content;
  }

  .dateTime {
    width: 350px;
  }

  .endText {
    width: 360px;
  }

  .endInput {
    flex-direction: column;
  }

  .number {
    margin-right: 2%;
    margin-bottom: 5%;
  }

  .price input {
    width: 221.7px;
  }

  .endLink {
    margin-top: 30%;
  }
}

/* Small devices*/
@media only screen and (min-width: 600px) and (max-width: 767px) {
  .mobileMenu {
    display: unset;
    margin-bottom: -10%;
    margin-top: 2%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0% 5%;
  }

  .mobileMenu .onorioLogo {
    width: 135px;
    height: 40px;
  }

  .desktopMenu {
    display: none;
  }

  /* body */

  .body {
    margin-top: -90%;
    margin-left: 0%;
    padding-right: 2%;
    padding-left: 1%;
  }

  .middle {
    margin-left: 2%;
  }

  .end {
    width: 60%;
    margin-top: 6%;
    margin-left: 5%;
  }

  .endText {
    font-size: 10px;
    width: 280px;
  }

  .endInput {
    display: flex;
    flex-direction: column;
  }

  .number input {
    margin-right: 0%;
    margin-bottom: 5%;
    width: 186.74px;
  }

  .end Button {
    margin-top: -78%;
  }
}

/* Medium devices */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mobileMenu {
    display: unset;
    margin-bottom: -10%;
    margin-top: 2%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0% 5%;
  }

  .mobileMenu .onorioLogo {
    width: 135px;
    height: 40px;
  }

  .desktopMenu {
    display: none;
  }

  /* body */

  .body {
    margin-top: -90%;
    margin-left: 0%;
    padding-right: 2%;
    padding-left: 1%;
  }

  .end {
    width: 60%;
    margin-top: 6%;
    padding-left: 2%;
  }

  .end Button {
    margin-top: 10%;
  }
}

/* Large devices  */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mobileMenu {
    display: unset;
    margin-bottom: -10%;
    margin-top: 2%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0% 5%;
  }

  .mobileMenu .onorioLogo {
    width: 135px;
    height: 40px;
  }

  .desktopMenu {
    display: none;
  }

  /* body */

  .body {
    margin-top: -90%;
    margin-left: 0%;
  }

  .end {
    width: 43%;
    margin-left: 4%;
  }

  .end Button {
    margin-top: 20%;
  }
}

/* xl devices  */
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  /* body */

  .end {
    width: 29%;
  }
}
