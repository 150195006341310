.eventsScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.space {
  display: flex;
  width: 160px;
  height: 2px;
  /* background-color: #000000; */
}

/* vertical title */
.verticalContainer {
  margin-left: 100px;
  margin-top: 35px;
}
.varietyeventscontainer {
  margin-left: 100px;
}

.varietyevents {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  
}

.varietyevents p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  text-transform: uppercase;
  color: #B78400;
  width: 161px;
  margin-right: 2%;
}

.varietyevents img {
  margin-bottom: 1%;
  width: 70vw;
}

.verticalTitle {

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;

  color: #400000;

}

.verticalDivider {
  display: flex;
  width: 160px;
  background-color: #3A0700;
  height: 2px;
  border-radius: 5px;
}

/* title & divider */
.titleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-self: center;
}

.eventsTitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #A07300;
  text-align: center;
}

.dividerContainer {
  width: 60vw;
  height: 1px;
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-self: center;
}

.dividerGrey {
  width: 45%;
  background-color: #E2E0D9;
  height: 1px;
  border-radius: 5px;
}

.dividerGold {
  width: 10%;
  background-color: #A07300;
  height: 1px;
  border-radius: 5px;
}

/* events */
.eventsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  width: 50vw;
  /* align-self: center; */
  /* background: #000;*/
}

.card {
  width: 60vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.bgImage {
  width: 100%;
  height: 30vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
}

.TextOnImage {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 7%;
}

.TextOnImageTitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 0 5%;
}

.TextOnImageDescription {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF;
}

.underImage {
  width: 50vw;
  margin-top: 2%;
  margin-bottom: 5%;
}

.TextUnderImage {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  color: #3D2C00;
}

.rec.rec-slider-container {
  overflow: visible;
}

.eventsDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  width: 100%;
  background-color: #00008b;
  color: #fff;
  margin: 0 15px;
  font-size: 4em;

}

.carousl,
.carousel-innr,
.carousel-inner>.carousel-item {
  overflow: hidden;
}

.carousel-inner>.carousel-item.active,
.carousel-inner>.carousel-item-next {
  display: flex;
  flex-wrap: nowrap;
}

.carousel-inner:before {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 72%;
  left: 0;
  content: "";
  display: block;
  background-color: #fff;
  z-index: 2;
}

.carousel-inner:after {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 72%;
  content: "";
  display: block;
  background-color: #fff;
  z-index: 2;
}

.carousel-control-prev {
  z-index: 2000;
  left: 22%;
}

.carousel-control-next {
  z-index: 2000;
  right: 22%;
}
.swipercontainer {
  margin-top: 1.5%;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  --swiper-theme-color: #b78400 !important;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination {
  position: initial !important;
  margin-top: 10% !important;

}

.swiper-pagination-bullet {
  background-color: #b78400 !important;
}

:root.swiper-pagination {
  bottom: 0;
}

.eventDescription {
  width:915px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;

  color: #000000;
}



/* Medium devices */
@media only screen and (min-width: 900px) and (max-width: 1400px) {
  .TextOnImageTitle {
    font-size: 18px;
    line-height: 24px;
  }

  .desktopPage {
    display: block;
  }

  .mobilePage {
    display: none;
  }
}

@media only screen and (min-width: 1400px) {
  .desktopPage {
    display: block;
  }

  .mobilePage {
    display: none;
  }
}

/* Medium devices */
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .desktopPage {
    display: block;
  }

  .mobilePage {
    display: none;
  }
  .varietyevents img {
    margin-bottom: 1%;
    width: 56vw;
  }



  .space {
    display: none;
  }

  .eventsScreen {
    /* margin-top: 5vh; */
    justify-content: center;
  }

  /* .titleContainer {
    margin: 5% 0
  } */
  .eventsContainer {
    margin-top: 5%;
  }

  .TextOnImageTitle {
    font-size: 14px;
    line-height: 20px;
  }

  .TextUnderImage {
    font-size: 10px;
  }
}


@media only screen and (max-width: 600px) {
  .desktopPage {
    display: none;
  }

  .mobilePage {
    display: block;
  }



  .space {
    display: none;
  }

  .eventsScreen {
    /* margin-top: 5vh; */
    justify-content: center;
  }

  .titleContainer {
    /* margin: 5% 0 */
    margin-top: 5%;
  }

  .card {
    width: 90vw;
  }

  .bgImage {
    height: 30vh;
  }

  /* .TextOnImage{
   
  } */
  .eventsContainer {
    margin-top: 5%;
  }

  .TextOnImageTitle {
    font-size: 12px;
    line-height: 15px;
  }

  .TextOnImageDescription {
    font-size: 10px;
    line-height: 12px;
  }

  .underImage {
    width: 90vw;
  }

  .TextUnderImage {
    font-size: 10px;
    text-align: left;
  }
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
  .desktopPage {
    display: none;
  }

  .mobilePage {
    display: block;
  }

}