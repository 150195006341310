.navBarMobile {
  display: flex;
  min-width: 100%;
  justify-content: space-between;
  background-color: #293b56;
  position: absolute;
  top: 0px;
  padding: 10px;
  border-bottom: outset 1px white;
}

.navBar__buttons {
  border: 2px red solid;
  text-decoration: none;
}

.mobilNavBar {
}

.mobileNavBar__link {
  text-decoration: none;
}

.mobileNavBar__link span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: white;
}

.mobileNavBar__link__press {
  margin-left: 12%;
}
/* 
a{
  width: 100%;
} */
