.body{
 display: flex;
 flex-direction: column;
 /* justify-content: center; */
 /* align-items: center; */
}
.titleContainer {
  top: -120px;
  width: 80vw;
  /* justify-content: center; */
  /* align-items: center; */
  display: flex;
  flex-direction: column;
  width: auto;
  position: relative;
  align-self: center;
}
.row{
  width: 80vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}
.centered-button {
  display: block;
  margin: 0 auto;
  text-align: center;
  background-color: #293b56;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.centered-button:hover {
  background-color: rgb(1, 1, 65)
}
.whiteDivider{
  width: 90%;
  background-color: #fff;
  height: 1px;
  border-radius: 5px;
}
.goldDivider{
  width: 10%;
  background-color: #A07300;
  height: 1px;
  border-radius: 5px;
}
.latestNewsContainer{
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  /* align-self: center; */
  /* width: 100%; */
}
.latestNews {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  padding-left: 5px;
  text-transform: uppercase;
  text-align: left;
  color: white;
}

.line {
  width: 80%;
  margin-bottom: 10px;
}
.moreNewsContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-self: center;
  margin-bottom: -100px;
}
.dividerContainer{
  width: 60vw;
  height: 1px;
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-self: center;
}
.dividerGrey{
  width: 45%;
  background-color: #E2E0D9;
  height: 1px;
  border-radius: 5px;
}
.dividerGold{
  width: 10%;
  background-color: #A07300;
  height: 1px;
  border-radius: 5px;
}
.latest_cards {
  display: flex;
  /* justify-content: center; */
  position: relative;
  top: -120px;
  width: 80vw;
  align-self: center;
}

.latest_cards_container {
  
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 80vw;
  /* margin-top: 30px; */
}

.latest_card_container {
  /* margin: 10px 20px; */
  margin-top: 30px;
  /* box-shadow: 0px 5px 5px 1px #aaaaaa; */
}

.latestCard {
  background: #1c3d47;
  width: 250px;
  height: 440px;
  display: flex;
  flex-direction: column;
  /* padding: 0 8%; */
  /* align-items: center; */
}

.imagelatest {
  width: 65%;
  height: 160px;
  align-self: center;

}
.imageBg{
  background: rgba(196, 196, 196, 0.15);
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cardTextContainer{
  padding: 4% 8%; 
}
.titleLatest {
  /* position: relative; */
  /* top: 10px;
  left: 10px; */
  /* width: 200px; */
  /* height: 21px; */
  text-align: start;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #c28c00;
}

.dateLatest {
  position: relative;
  /* left: 20px; */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #bdbdbd;
}

.contentLatest {
  position: relative;
  /* left: 10px; */
  text-align: start;
  margin-right: 10px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #ffffff;
}

.header {
  display: flex;
  justify-content: center;
}

.title {
  display: flex;
  justify-content: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: #b78400;
  /* margin-left: 2%; */
}

@media only screen  and (min-width: 899px) and (max-width: 1310px) {
  .moreNewsContainer{
    margin-bottom: -50px;
  }
}

@media only screen  and (min-width: 938px) and (max-width: 1310px) {
 
.latest_cards_container{
  width: 57vw;
  align-self: center;
}
.latest_cards{
  justify-content: center;
}
}

@media only screen and (min-width: 625px) and (max-width: 651px) {

  .latest_cards_container{
    width: 75vw;
    align-self: center;
  }
  .latest_cards{
    justify-content: center;
  }
}

@media only screen and (min-width: 600px) and (max-width: 899px) {
  .moreNewsContainer{
    margin-bottom: 0;
  }
  .header {
    height: 50vh;
  }
  .headerblock {
    width: 90vw;
    margin-right: 0;
  }
  .headerblockTitle {
    font-size: 14px;
    line-height: 20px;
    width: 200px;
  }
  .headerblockContent {
    width: 70vw;
    font-size: 10px;
  }


}

@media only screen and (max-width: 599px) {
  .moreNewsContainer{
    margin-bottom: 0;
  }
  .latestNews {
    font-size: 14px;
  }

  .titleLatest {
    font-size: 12px;
    line-height: 15px;
  }

  .dateLatest {
    font-size: 9px;
  }

  .contentLatest {
    position: relative;
    /* left: 10px; */
    text-align: start;
    margin-right: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: #ffffff;
  }
  .title {
    font-size: 18px;
    line-height: 27px;
  }



  
}