.header {
  overflow-y: hidden;
  background-image: url("https://duronnspaces.nyc3.digitaloceanspaces.com/ocm/devotional.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.prayer,
.story,
.locations {
  color: black;
}

.locations {
  width: 80%;
}
