.bookingHeader {
  background-image: url("../../../assets/cover booking.png");
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 10%;
}

.title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-transform: uppercase;
  color: #ffffff;
}

.text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  width: 46%;
  margin-top: 1%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 599px) {
  .bookingHeader {
    background-color: #1c3d47;
    min-height: 50vh;
    padding-top: 40%;
  }

  .bookingHeader img {
    width: 90%;
  }

  .title {
    font-size: 20px;
  }

  .text {
    font-size: 10px;
    width: 90%;
  }
}

/* Small devices*/
@media only screen and (min-width: 600px) and (max-width: 767px) {
  .bookingHeader {
    padding-bottom: 90%;
  }

  .bookingHeader img {
    width: 90%;
  }

  .title {
    font-size: 20px;
  }

  .text {
    font-size: 10px;
    width: 90%;
  }
}

/* Medium devices */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bookingHeader {
    padding-bottom: 90%;
  }

  .title {
    font-size: 22px;
  }

  .text {
    font-size: 12px;
    width: 80%;
  }
}

/* Large devices  */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bookingHeader {
    padding-bottom: 90%;
  }
}

/* xl devices  */
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
}
