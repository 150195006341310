.modale_button_container {
  width: 8vw;
  display: flex;
  position: relative;
  top: 38vh;
  left: 15vw;
}

.profile_picture {
  border-radius: 50%;
  border: 3px white solid;
  width: 130px;
  height: 130px;
}

.profile_picture_iconEdit {
  position: relative;
  top: 8vh;
  height: 30px;
  width: 30px;
  right: 1vw;
  background-color: #805C00;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-content: center;
  border: 1px solid white;
}

.modal_titre {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF;
}

.modal_body_img {
  border-radius: 50%;
  width: 130px;
  border: 1px solid #FFFFFF;
}

.buttons_container {
  display: flex;
  justify-content: space-between;
}

.modal_footer_titre {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF;
}

#saveModalButton {
  border: 1px white solid;
  border-radius: 10px;
  background-color: transparent;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF;
}

@media only screen and (max-width: 600px) {
  .modal_body_img {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    border: 1px solid #FFFFFF;
  }

  .modale_button_container {
    width: 8vw;
    display: flex;
    position: relative;
    top: 25vh;
    left: 15vw;
  }

  .profile_picture {
    border-radius: 50%;
    border: 3px white solid;
    width: 80px;
    height: 80px;
  }

  .profile_picture_iconEdit {
    right: 5vw;
  }

  .modal_titre {
    font-size: 20px;
    line-height: 18px;
  }
}