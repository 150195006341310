.chatCards{
  width: 69vw;
}
.signInContainer{
  width: 70vw;
  margin-left: 25vw;
}
.shareYourStory {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}
.cardImg {
  left: 5vw;
}

.title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-left: 20%;
}
.signin {

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin-top: 1.5%;
}

.signin p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  color: #1c3d47;
  text-align: center;
}

.share {
  margin-right: 1%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  letter-spacing: 0.05em;
  color: #1c3d47;
}

.story {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.05em;
  color: #b78400;
  margin-top: 1%;
}

/* chat */

.chat {
  height: 710px;
  width: 71vw;
  overflow-y: scroll;
  overscroll-behavior-y: hidden;
  
}

.storyCard,
.commentCard {
  display: flex;
  justify-content: center;
  align-items: center;

}

.storyCard img {
  margin-right: 1%;
}


.cardBody {
  background-image: url("../../../assets/Group.png");
  width: 800px;
  height: auto;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
  margin-left: 5%;
  padding: 3% 5%;
  text-align: left;
  background-size: cover;
}

.cardBody p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #666666;
}

/* comment card */

.commentCard > img {
  margin-right: -3%;
  margin-left: 12%;
  margin-bottom: 2%;
  margin-top: -3%;
}

.commentCard .card img {
  margin-top: -3%;
}
 .add_comment_card  {
  margin-top: -3%;
}
.commentCard .cardBody {
  background-image: url("../../../assets/Group\ \(1\).png");
  width: 740px;
  height: auto;
  margin-top: -1%;
  margin-bottom: 3%;
}
.addComment_to_Story{
  width: 740px;
}
/* react buttons */

.reactButtons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: -1%;
  margin-left: 0.5%;
}

.commentButton {
  margin-top: 7px;
}


/* post your story */
.inputContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}
.postStoryTitle {
  position: relative;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #1c3d47;
  align-self: flex-start;
  /* margin-left: 11%; */
  margin-top: 2%;
  left: 5px
}

.postStory {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: url("../../../assets/Group\ \(2\).png");
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
  width: 800px;
  align-self: flex-end;
  height: 76px;
  padding: 0% 2%;
  /* margin-left: 16%; */
  margin-bottom: 2vh;
  position: relative;
/* left: 188px */
}
.addCommentContainer{
  width: 69vw;
  display: flex;
  justify-content: center;
  /*align-items: center; */
}
.addComment_to_Story {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: url("../../../assets/Group\ \(2\).png");
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
  width: 800px;
  align-self: flex-end;
  height: 76px;
  padding: 0% 2%;
  /* margin-left: 16%; */
  margin-bottom: 2vh;
  position: relative;
  margin-left: 5%;
  /* left: 188px */
}

.postStory p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: #adadad;
  /* margin-top: 1%; */
  /* margin-left: 2%; */
}
.addComment_to_Story {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: #adadad;
  /* margin-top: 1%; */
  margin-left: 8%
}
.card > img {
  position: relative;
  z-index: 1;
  height: 48px;
  top: 2vh;
}
.add_comment_card  {
  position: relative;
  z-index: 1;
  height: 48px;
  top: 2vh;
}
.profile {
  left: 5vw
}

#profile_card {
  top: 2vh;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 599px) {
  /* chat cards */

  .chat {
    height: 770px;
    width: 85vw;
    margin-right: -50px;
  }

  /* chat body */

  .storyCard > img {
    width: 36px;
    height: 3px;
    top: 1vh;
  }

  .shareYourStory {
    margin-top: 10%;
    padding-right: 12%;
  }

  /* story card body */

  .cardBody {
    background-image: none;
    background-color: #ffffff;
    border-bottom-right-radius: 30px;
    border-top-left-radius: 20px;
    width: 70vw;
    height: auto;
    background-repeat: no-repeat;
    margin-left: 5%;
    padding: 8% 7%;
    text-align: left;
  }

  .addComment_to_Story{
    width: 70vw;
  }
  /* comment card  */
.add_comment_card{
  top:1vh
}
  .commentCard .cardBody {
    background-image: none;
    width: 210px;
    height: auto;
    margin: 8% 0% 3% 8%;
  }

  .commentCard img {
    margin-left: 16%;
  }

  .commentCard .card img {
    margin-top: 8%;
  }
 .add_comment_card {
    padding: 0 3%;
    margin-top: 1%;
  }
  .card .profile {
    margin-bottom: -18%;
    margin-left: 2%;
  }
  /* .add_comment_card {
    margin-bottom: -18%;
    margin-left: 2%;
  } */

  /* post your story card */
  .storyCard {
    margin-top: 1vh;
  }
  .postStory {
    background-image: none;
    background-color: #ffffff;
    border-bottom-right-radius: 30px;
    border-top-left-radius: 20px;
    width: 70vw;
    height: 76px;
    padding: 0% 2%;
    margin-left: 22%;
    position:relative;
    top: 10px;
    left: 1px;
  }

  .postStoryTitle {
    /* margin-top: 10%; */
  }

  .postStory p {
    margin-left: 4%;
    margin-top: 4%;
  }

  #profile_card {
    top: 0vh;
  }
}

/* Small devices*/
@media only screen and (min-width: 600px) and (max-width: 767px) {
  /* chat cards */

  .chat {
    height: 740px;
    width: 80vw;
    margin-right: -50px;
  }

  /* chat body */

  .shareYourStory {
    margin-top: 7%;
    padding-right: 12%;
  }

  /* story card body */

  .cardBody {
    background-image: none;
    background-color: #ffffff;
    border-bottom-right-radius: 30px;
    border-top-left-radius: 20px;
    width: 380px;
    height: auto;
    background-repeat: no-repeat;
    margin-left: 5%;
    padding: 3% 5%;
    text-align: left;
  }
  .addComment_to_Story{
    width: 380px;
    margin-top: 1%;
  }

  /* comment card  */

  .commentCard .cardBody {
    background-image: none;
    width: 338px;
    height: auto;
    margin: 5% 0% 3% 8%;
  }
  /* .addComment_to_Story{
    width: 338px;
  } */
  .commentCard img {
    margin-left: 16%;
  }

  .card .profile {
    margin-bottom: -18%;
    margin-left: 2%;
  }
  /* .add_comment_card {
    margin-bottom: -18%;
    margin-left: 2%;
  } */

  /* post your story card */

  .postStory {
    background-image: none;
    background-color: #ffffff;
    border-bottom-right-radius: 30px;
    border-top-left-radius: 20px;
    width: 390px;
    height: 76px;
    padding: 0% 2%;
    margin-left: 24%;
    position:relative;
    top: 10px;
    left: 1px;
  }

  .postStoryTitle {
    /* margin-top: 4%; */
  }

  #profile_card {
    top: 0vh;
  }
}

/* Medium devices */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* chat cards */
  .card > img {
   
    top: 1vh;
  }
  .chat {
    height: 740px;
    width: 76vw;
    margin-right: -50px;
  }

  /* chat body */

  .shareYourStory {
    margin-top: 7%;
    padding-right: 10%;
  }

  /* story card body */

  .cardBody {
    background-image: none;
    background-color: #ffffff;
    border-bottom-right-radius: 38px;
    border-top-left-radius: 20px;
    width: 60vw;
    height: auto;
    background-repeat: no-repeat;
    margin-left: 5%;
    padding: 3% 5%;
    text-align: left;
  }
  .addComment_to_Story{
    width: 60vw;
    margin-top: 1%;
  }
  /* comment card  */

  .commentCard .cardBody {
    background-image: none;
    width: 420px;
    height: auto;
    margin: -1% 0% 3% 8%;
  }
  /* .addComment_to_Story{
    width: 420px;
  } */
  .commentCard img {
    margin-left: 16%;
  }

  .card .profile {
    margin-bottom: -10%;
    margin-left: 2%;
  }
  .add_comment_card .profile {
    margin-bottom: -10%;
    margin-left: 2%;
  }
  /* post your story card */

  .postStory {
    background-image: none;
    background-color: #ffffff;
    border-bottom-right-radius: 30px;
    border-top-left-radius: 20px;
    width: 60vw;
    height: 76px;
    padding: 0% 2%;
    margin-left: 22%;
    position:relative;
    top: 10px;
    left: 1px
  }

  #profile_card {
    top: -18px;
  }
  
}

/* Large devices  */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  /* chat cards */
  .card {
    top: -1vh;
    left: 0.5vw;
  }
  .add_comment_card {
   
    left: 0.5vw;
  }
  .chat {
    height: 740px;
    width: 75vw;
    margin-right: -50px;
  }

  /* chat body */

  .shareYourStory {
    margin-top: 5%;
    padding-right: 6%;
  }

  /* story card body */

  .cardBody {
    background-image: none;
    background-color: #ffffff;
    border-bottom-right-radius: 38px;
    border-top-left-radius: 20px;
    width: 650px;
    height: auto;
    background-repeat: no-repeat;
    margin-left: 5%;
    padding: 3% 3%;
    text-align: left;
  }
  .addComment_to_Story{
    width: 650px;
    margin-top: 1%;
  }
  /* comment card  */

  .commentCard .cardBody {
    background-image: none;
    width: 580px;
    height: auto;
    margin: -1% 0% 3% 7%;
  }
 
  .commentCard .card img {
    margin-top: 0%;
  }
  .commentCard .add_comment_card img {
    margin-top: 0%;
  }

  /* post your story card */

  .postStory {
    background-image: none;
    background-color: #ffffff;
    border-bottom-right-radius: 30px;
    border-top-left-radius: 20px;
    width: 650px;
    height: 76px;
    padding: 0% 2%;
    margin-left: 18%;
    position:relative;
    top: 10px;
    left: 10px
  }
}

/* xl devices  */
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .shareYourStory {
    margin-top: 5%;
    padding-right: 6%;
  }

  .chat {
    height: 740px;
    width: 77vw;
  }

  .postStory {
    position:relative;
    top: 10px;
    left: 25px
  }
}
