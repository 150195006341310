.articleOneTitle{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 22px;
/* identical to box height, or 122% */


background: linear-gradient(107.09deg, #1C3D47 11.42%, #B78400 61.76%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
color: transparent;
}
.articleOneContent{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 22px;
/* or 183% */


color: #5C5C5C;
}
.articleOneDecOration{
    position: relative;
    width: 529px;
height: 340px;
    background: linear-gradient(118.49deg, rgba(28, 61, 71, 0.76) 19.05%, rgba(183, 132, 0, 0.85) 91.88%);
border-radius: 20px 0px;
}
.articleOneImg{
    position: absolute;
    width: 529px;
height: 336px;
right: 3%;
top: 5%;
background: linear-gradient(69.43deg, rgba(183, 132, 0, 0.05) 7.75%, rgba(0, 0, 0, 0.98) 93.28%);
border-radius: 20px 0px;
}
.articleThreeImg{
    position: absolute;
    width: 529px;
height: 336px;
left: 3%;
bottom: 5%;
background: linear-gradient(69.43deg, rgba(183, 132, 0, 0.05) 7.75%, rgba(0, 0, 0, 0.98) 93.28%);
border-radius: 20px 0px;
}
.articleTwo{
    margin-bottom: 10%;
 z-index: -1;
width: 1207.48px;
height: 581px;
    background: linear-gradient(104.14deg, rgba(0, 0, 0, 0.46) 11.01%, rgba(183, 132, 0, 0.54) 94.88%);
}
.articleTwoBox{
    position: absolute;
     text-align: left;
     padding-left: 2%;
     padding-top: 5%;
    right: 10%;
    margin-top: 3%;
   float: right ;
    width: 426.57px;
height: 581px;
    background: #1D404A;
box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.articleTwoTitle{
    
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 27px;
/* identical to box height */


color: #F7F7F7;
}
.articleTwoContent{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 18px;
color: #FFFFFF;
}