.modal_body {
  display: block
}

@media only screen and (max-width: 599px) {
  .modal_body {
    display: block;
  }

  .Modal_body_input {
    margin-top: 20px;
    width: 75vw
  }
}