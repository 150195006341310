.news_body {
  display: flex;
  justify-content: center;
  margin: 5% 0px;
}

/* .news__title_container {
  position: relative; 
  top: 40px;
  left: 20px;
  right: 85px 
} */

.news__title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #1C3D47;
}

.news__date_container {
  position: relative;
  top: 15px;
  left: 15px;
}

.news__date {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: #666666;
}

.news__container {
  width: 60vw;
  height: 50vh;
}

.news__container_img {
  width: 50vw;
  height: 50vh;
}

.news__text {
  position: relative;
  top: 20%;
  left: 15%;
  width: 70%;
}

.cardTitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #FFFFFF;
}

.cardContent {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: #FFFFFF;
}

.cardColor {
  width: 30vw;
  height: 50vh;
  margin-top: 3vh;
  margin-left: -28vw;
  background: #926a05;
  position: relative;
  top: 10px;
}

.cardColor2 {
  position: relative;
  width: 30vw;
  height: 50vh;
  margin-right: -28vw;
  margin-top: 2vh;
  background: #1c3d47;
  top: 10px;
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .cardColor {
    height: 48vh;
    margin-top: 5vh;
  }

  .cardColor2 {
    position: relative;
    width: 30vw;
    height: 48vh;
    margin-right: -28vw;
    margin-top: 5vh;
    background: #1c3d47;
    top: 10px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .news__container {
     width: 70vw;
  }

  .cardColor {
    height: 40vh;
    margin-top: 10vh;
  }

  .cardColor2 {
    height: 40vh;
    margin-top: 10vh;
  }
}

@media only screen and (min-width: 600px) and (max-width: 899px) {
  .news_body {
    display: flex;
    justify-content: center;
    margin: 2% 0px;
  }

  .news__container {
     margin-top: 2%;
     width: 80vw; 
  }

  .news__container_img {
    width: 60vw;
    height: 35vh;
  }

  .cardColor {
    display: none
  }

  .cardColor2 {
    /* position: relative;
    width: 30vw;
    height: 45vh;
    margin-top: 5vh;
    margin-right: -28vw;
    background: #1c3d47;
    top: 20px; */
    display: none;
  }
}

@media only screen and (max-width: 599px) {

  .news__title_container {
    margin-left: 10px
  }

  .Style_news__title {
    font-size: 20px;
    line-height: 20px;
    color: #1C3D47;
    margin: 5px 0px;
  }

  .news__container {
    margin-top: 2%;
    width: 80vw;
    height: 42vh;
  }

  .news__text {
    position: relative;
    top: 15%;
    left: 10%;
    width: 70%;
  }

  .cardTitle {
    font-size: 18px;
    line-height: 25px;
  }

  .Style_cardContent {
    font-size: 11px;
    line-height: 18px;
  }

  .cardColor {
    /* width: 30vw;
    height: 42vh;
    margin-top: 2vh;
    margin-left: -28vw;
    background: #926a05;
    position: relative;
    top: 60px; */
    display: none;
  }

  .cardColor2 {
    /* position: relative;
    width: 30vw;
    height: 42vh;
    margin-right: -28vw;
    margin-top: 2vh;
    background: #1c3d47;
    top: 60px; */
    display: none;
  }
  .cardContent{
    font-size: 11px;
    line-height: 14px;
  }

}