@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,100&display=swap');

.eventsHeader {
  width: 100vw;
  height: 70vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-image: url('https://storage.googleapis.com/public_ocm/cover-events-page.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.eventsDescription {
  position: relative;
  width: 40vw;
  margin-top:100px;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
}
.eventsTitle {
  display: flex;
  align-items: center;
}
.recentEvents {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 52px;
  text-transform: uppercase;
  color: #ffffff;
  margin-right: 2%;
}
.kingdomTV {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 1.5%;
}
.eventsText {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: #ffffff;
  width: 90%;
  text-align: left;
}

/* Large devices  */
@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .eventsDescription{
    padding-left: 15px;
  }
  .eventsTitle {
    display: block;
    align-items: flex-start;
  }
  .recentEvents {
    font-size: 33px;
    line-height: 37px;
  }
  .eventsText {
    font-size: 11px;
    line-height: 18px;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 600px) and (max-width: 899px) {
  .eventsDescription {
    width: 80vw;
    margin-top: 30px;
    margin-left: 10vw;
  }
  .eventsHeader {
    background-image: url('https://storage.googleapis.com/public_ocm/cover-events-mobile-page.png');
    justify-content: flex-start;
    /* min-height: 40vh; */
    height: 50vh;
  }
  .eventsTitle {
    display: block;
    align-items: center;
  }
  .kingdomTV {
    font-size: 15px;
    line-height: 24px;
  }
  .recentEvents {
    font-size: 33px;
    line-height: 37px;
  }
  .eventsText {
    font-size: 11px;
    line-height: 18px;
  }
}

@media only screen and (max-width: 599px) {
  .eventsDescription {
    width: 80vw;
    margin-top: 35px;
    margin-left: 10vw;
  }
  .eventsHeader {
    background-image: url('https://storage.googleapis.com/public_ocm/cover-events-mobile-page.png');
    justify-content: flex-start;
    height: 50vh;
    /* min-height: 40vh; */
  }
  .eventsTitle {
    display: block;
    align-items: center;
  }
  .recentEvents {
    font-size: 25px;
    line-height: 24px;
  }
  .kingdomTV {
    font-size: 15px;
    line-height: 24px;
  }
  .eventsText {
    font-size: 11px;
    line-height: 18px;
  }
}