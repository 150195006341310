.title {
  display: flex;
  align-items: center;
  margin-top: 5%;
  margin-left: 5%;
}

/* .locations_body {
  width: 100%
} */



.title p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.05em;
  color: #1c3d47;
  margin-right: 1%;
}

.title > img {
  margin-bottom: 0.8%;
}

.onorio {
  justify-content: flex-start;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #b78400;
  text-align: left;
  margin-left: 5%;
}

/* cards */
.cardsContainer {
  display:flex;
  justify-content: center;
  width: 100vw;
}
.cards {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  align-items: center;

}

.card {
  background-color: #ffffff;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
  width: 343px;
  height: 380px;
  margin: 5vw;
  padding: 1% 0% 0% 0%;
}

.cardTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #1c3d47;
  text-align: left;
  margin-top: 4%;
  margin-left: 4%;
}

.cardText {
  display: flex;
  justify-content: space-between;
  padding: 0% 4%;
  margin-top: 2%;
}

.address {
  width: 62%;
  height: auto;
}

.contact,
.legacy {
  width: 109px;
  height: 40px;
}

.addressTitle,
.addressText,
.contactTitle,
.contactText,
.legacyTitle,
.legacyText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  color: #3d2c00;
  text-align: left;
}

.addressTitle,
.contactTitle,
.legacyTitle {
  margin-bottom: -0.5%;
}

.legacyTitle {
  margin-top: 4%;
}

.addressText,
.contactText,
.legacyText {
  color: #b78400;
}

.addressText p {
  margin-bottom: 1%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 599px) {
  /* .cards {
    justify-content: center;
  } */

  .address {
    width: 65%;
    height: auto;
  }

  .title {
    margin-left: 1%;
  }

  .title > img {
    margin-bottom: 4%;
    margin-left: 2%;
  }

  .onorio {
    margin-left: 3%;
    margin-top: -3%;
  }
}

/* Small devices*/
@media only screen and (min-width: 600px) and (max-width: 767px) {
  /* .cards {
    justify-content: center;
  } */

  .title {
    margin-left: 1%;
  }

  .title > img {
    margin-bottom: 2%;
    margin-left: 2%;
  }

  .onorio {
    margin-left: 3%;
    margin-top: -3%;
  }
}

/* Medium devices */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* .cards {
    justify-content: center;
  } */

  .title {
    margin-left: 1%;
  }

  .title > img {
    margin-bottom: 2%;
    margin-left: 2%;
  }

  .onorio {
    margin-left: 3%;
    margin-top: -2%;
  }
}

/* Large devices  */
@media only screen and (min-width: 992px) and (max-width: 1199px) {

  .title {
    margin-left: 1%;
  }

  .onorio {
    margin-left: 3%;
  }
}

/* xl devices  */
@media only screen and (min-width: 1200px) and (max-width: 1400px) {

}
