.body {
  margin-top: 15vh;
  padding-bottom: 5vh;
  display: flex;
  justify-content: center;

}

#firstCard {
  height: 75%;
}

#forthCard {
  height: 75%
}

.title_container {
  display: flex;
  justify-content: center;
}

.goldLine {
  height: auto;
  width: 1px;
  background-color: #B78400;
}

.gold_container {
  width: 60%;
  position: relative;
  top: 20px;
  left: 40px;
}

.textGold {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-transform: uppercase;
  color: #1C3D47;
}

.descriptionGold {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  color: #7C7C7C;
}

.cards {
  display: flex;
  justify-content: center;
}

.cards_container {
  width: 80%;
  margin-top: 3vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.help {
  margin-top: 3vh;
}

.helpImg {
  width: 50vw;
  height: 55vh;
}

.helpContent {
  display: flex;
  position: relative;
  top: 7vh;
  left: 5vw;
}

.goldBar {
  width: 10px;
  height: 5vh;
  background: #B78400;
}

.help_container {
  display: flex;
  justify-content: center;
  margin-top: 10vh;
}

.helpContainer_text {
  padding-left: 40px;
  padding-top: 30px;
}

.helpTitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 21px;
  color: #FFFFFF;
}

.helpDescription {
  width: 60%;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 90%;
  line-height: 20px;
  color: #FFFFFF;
}

.helpCard {
  width: 35vw;
  height: 55vh;
  background-color: #F3F3F3;
  padding: 3vh 3vw;
}

/* .helpCard_title {} */

.giving {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 21px;
  color: #0F2B34;
}

.vGold {
  background: #B78400;
  width: 4vw;
  height: 5px;
}
.button_text {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 16px;
color: #FFFFFF;
border: none;
margin: 10px 15px;
width: 113px;
height: 37px;
border-radius: 5px 0px;
text-decoration: none;
}
.button_text_disabled {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 6px;

color: #FFFFFF;
border: none;
margin: 10px 15px;
width: 113px;
height: 37px;
border-radius: 5px 0px;
}
.helpCard_text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  color: #FFFFFF;

}

.buttons_container {
  margin: 2vh 0vw;
}

#oneTimeButton {
  background: #c8a95a;
}

#WeeklyButton {
  background: #9e7f31;
}

#monthlyButton {
  background: #B78400;
}

.helpCard_checkOut {
  display: flex;
  justify-content: center;
  margin-top: 3vh;
}

.offer {
  background-color: #F5F5F5;
  height: 6vh;
  width: 40vh;
  padding: 10px 10px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #686868;
  border: 0.5px solid #AFAFAF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border-left: 5px solid #1C3D47;
}

.checkout {
  position: relative;
  top: 13vh;
}

#checkoutButton {
  background: #1C3D47;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #FFFFFF;
  width: 99px;
  height: 42px;
  border: none;
  border-radius: 5px;
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .cards_container {
    width: 65%;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {

   .offer {    
    padding: 10px 10px;
    height : 42px;
   width: 300px;
   }
  .cards_container {
    width: 75%;
  }

  .help {
    display: flex;
    justify-content: center;
  }

  .help_container {
    display: flex;
    flex-direction: column;
  }

  .helpImg {
    width: 70vw;
    height: 50vh;
  }

  .helpCard {
    width: 70vw;
    height: 50vh;
  }
}

@media only screen and (min-width: 600px) and (max-width: 899px) {
  .helpContainer_text {
    padding-top: 0px;
    font-size: 11px;
  }

  .help {
    display: flex;
    justify-content: center;
  }

  .help_container {
    display: flex;
    flex-direction: column;
  }

  .helpImg {
    width: 70vw;
    height: 55vh;
  }

  .helpCard {
    width: 70vw;
    height: 55vh;
  }

  .cards_container {
    width: 100%;
  }
  .offer {    
    padding: 10px 10px;
    height : 42px;
   width: 300px;
   }

}

@media only screen and (max-width: 599px) {
  .body {
    margin-top: 10vh;
  }

  .goldLine {
    height: 35vh;
  }

  .textGold {
    font-size: 16px;
    line-height: 22px;
  }

  .descriptionGold {
    font-size: 11px;
  }

  .cards_container {
    width: 100%;
  }

  .help {
    display: flex;
    justify-content: center;
  }

  .help_container {
    display: flex;
    flex-direction: column;
    margin-top: 5vh;
  }

  .helpImg {
    width: 90vw;
    height: 44vh;
  }

  .helpContent {
    margin: 3vh 2vw;
    position: static;
  }


  .goldBar {
    width: 20px;
  }

  .helpContainer_text {
    padding-left: 20px;
    padding-top: 0px;

  }

  .helpTitle {
    font-size: 20px;
  }

  .helpDescription {
    width: 90%;
    font-size: 11px;
  }

  .helpCard {
    width: 90vw;
    height: 50vh;
    padding: 0px;
    min-height: 280px;
  }

  .helpCard_title {
    padding: 0px 3vw;
  }

  .giving {

    font-size: 17px;

  }

  .vGold {
    width: 50px;
    height: 4px;
  }

  .buttons_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .checkout {
    position: static;
  }

  .helpCard_checkOut {
    display: flex;   
    margin-top: 2vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .helpCard_checkOut_text {
    display: flex;
    align-items: center;
  }

  .offer {
   padding: 10px 10px;
   height : 42px;
   width: 300px;
  }

}