.NewsHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.bgPrim {
  background-color: #b78400;
  font-size: 40px;
}
.header {
  width: 100vw;
  height: 70vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-image: url("../../../assets/devotional.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 5%;
}
.carouContent {
  height: 300px;
}
.headerblock1 {
  width: 600px;
  background-color: #b78400;
  margin-top: 120px;
  padding: 3%;
  height: 100;
}
.headerblock {
  width: 391px;
  height: auto;
  background: rgba(196, 196, 196, 0.1);
  border-radius: 10px 0px;
}
.headerblockGold {
  position: relative;
  width: 4px;
  height: 40px;
  top: 30px;
  background: #b78400;
}
.headerblockTitle {
  position: relative;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  text-align: start;
  width: 300px;
  left: 20px;
  top: -10px;
  text-transform: uppercase;
  color: #ffffff;
}
.headerblockContent {
  position: relative;
  width: 337px;
  height: 64px;
  top: -5px;
  font-family: "Poppins";
  left: 20px;
  text-align: start;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;
  /* or 182% */

  color: #ffffff;
}

@media only screen and (min-width: 600px) and (max-width: 899px) {
  .header {
    height: 60vh;
  }
  .headerblock {
    width: 90vw;
    margin-right: 0;
  }
  .headerblockGold {
    height: 20px;
    top: 10px;
  }
  .headerblockTitle {
    width: 70vw;
    font-size: 14px;
    line-height: 20px;
  }
  .headerblockContent {
    width: 70vw;
    font-size: 10px;
  }
}

@media only screen and (max-width: 599px) {
  .header {
    height: 60vh;
  }
  .headerblock {
    width: 90vw;
    margin-right: 0;
  }
  .headerblockGold {
    height: 20px;
    top: 10px;
  }
  .headerblockTitle {
    width: 70vw;
    font-size: 14px;
    line-height: 20px;
  }
  .headerblockContent {
    width: 70vw;
    font-size: 10px;
  }
}
